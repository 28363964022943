import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { UseAuth } from "../hooks/useAuth";


const PrivateRoute = (props) => {
   const auth = UseAuth();
   //console.log(auth);
   // If authorized, return an outlet that will render child elements
   // If not, return element that will navigate to login page
   return auth.user ? <Outlet /> : <Navigate to="/login" />;
}

export default PrivateRoute