import React, { useEffect, useState } from 'react';
//import Footer from "../Footer"
//import { Link } from "react-router-dom"
function ReferAndEarnTOS() {
    useEffect(() => {
        document.title = `Refer & Earn | VasiApp`
        window.scrollTo(0, 0)
    }, [])


    // FAQ data
    const faqData = [

        {
            question: 'How do I participate in the Refer & Earn program?',
            answer: 'Participating in the VaSi Refer & Earn program is easy! <br/>Follow these steps to get started:            <ul><li><strong>Locate Your Coupon Code:</strong> Log into your VaSi account and navigate to the \'Refer & Earn\' section. Here, you’ll find your unique coupon code.</li><li><strong>Share Your Code:</strong>  Share this code with your friends, family, or anyone else you know who might be interested in signing up for VaSi. You can send it directly via email, SMS, or through social media platforms.</li><li><strong>Earn Rewards:</strong>  When your friends sign up at VaSi using your unique coupon code, they need to ensure the code is entered during the registration process. Once they complete their signup and onboard successfully, you will receive INR 50 as a referral bonus. Additionally, each new user who signs up using your coupon code will receive INR 10 in their VaSi wallet.</li></ul>',
        },

        {
            question: 'Where can I find my referral coupon code?',
            answer: 'You can find your unique referral coupon code directly in your VaSi mobile app. Here’s how to locate it:<ul><li><strong>Open the App:</strong>  Launch the VaSi app on your smartphone.</li><li><strong>Go to the Profile:</strong>  Tap on the profile icon,  located at the top corner of the screen.</li><li><strong>Navigate to \'Refer & Earn\':</strong>  Find and select the ‘Refer & Earn’ option from the menu list.</li><li><strong>View Your Code:</strong>  Your unique referral coupon code will be displayed in this section. You can share this code with your friends and family to invite them to join VaSi, and start earning rewards!</li></ul>',
        },

        {
            question: 'Is there a limit to how many people I can refer?',
            answer: 'There is no limit to the number of people you can refer to VaSi! You are encouraged to share your unique referral coupon code with as many friends, family members, and acquaintances as you wish. For every person who signs up for VaSi using your referral code and successfully completes the onboarding process, you will earn INR 50. The more successful referrals you make, the more rewards you can earn!<br/>Please note, you can continue to invite users and earn rewards as long as the Refer & Earn program is active. VaSi reserves the right to modify or discontinue the program at any time, so take advantage of this opportunity while it lasts!',
        },

        {
            question: 'When will I receive my referral reward?',
            answer: 'Your referral rewards will be credited to your VaSi wallet immediately after your invitee successfully completes the onboarding process. This means as soon as they sign up for VaSi using your referral code and meet all the required conditions for a successful registration, you will see the reward added to your wallet. Keep inviting friends to maximize your rewards!',
        },

        {
            question: 'What happens if my invitee forgets to use my coupon code?',
            answer: 'The referral reward is only credited if your invitee enters your unique coupon code at the time of their signup. Unfortunately, if the coupon code is not used during the registration process, the referral reward cannot be credited to your account. Please remind your invitees to use the code when they sign up to ensure you both benefit from the rewards!',
        },

        {
            question: 'Can promotional credits be exchanged for cash?',
            answer: 'No, promotional credits received through the Refer & Earn program have no cash value and cannot be exchanged for cash. These credits are exclusively for use within the VaSi platform to make purchases. This ensures that the rewards you earn contribute directly towards enhancing your experience with our services.',
        },

        {
            question: 'How can I receive my referral earnings in my bank account?',
            answer: ' To transfer your referral earnings from your VaSi wallet to your bank account, you need to link a payment processor to your VaSi profile. You can choose between Stripe or PayPal for this purpose. Here’s how to set it up:<br/><ul><li><strong>Link Your Account:</strong>  Go to your profile settings in the VaSi app and select ‘Payment Methods’. From there, you can add and link either a Stripe or PayPal account.</li><li><strong>Verify Your Account:</strong>  Ensure that your Stripe or PayPal account is fully set up and verified. This step is crucial for successful fund transfers.</li><li><strong>Initiate Transfer:</strong>  Once your payment account is linked and verified, you can transfer funds from your VaSi wallet to your bank account directly through the linked payment processor.</li></ul>For detailed instructions on setting up your preferred payout method and additional terms, please refer to our <a style="color:#F8D546" href="https://www.learnee.ai/payout-tos">https://www.learnee.ai/payout-tos</a>',
        },

    ]


    const [expandedIndex, setExpandedIndex] = useState(0);

    const toggleExpand = (index) => {
        setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return (
        <div className='position-rel'>
        <div className='background-image'></div>
            <div className="outer-page-color">
                <header className="margin-top-8">
                    <div className="px-5 text-center">
                        <h2 className='font-weight-700 padding-top-10'>Refer & Earn <br />
                            (Terms of Service)</h2>
                    </div>
                </header>
                <aside className="inner_pages">
                    <div className="px-5">
                        <div className="row gx-5 refer-earn">
                            <h3 className='padding-left-0'>Eligibility:</h3>
                            <ul>
                                <li>The Refer & Earn program is available to all registered VaSi users based in India.</li>
                                <li>Participants, including referrers and invitees, must be at least 12 years of age at the time of participation.</li>
                            </ul>

                            <h3>Program Details:</h3>
                            <ul>
                                <li>Referrers earn INR 50 for each new user they successfully refer to VaSi who signs up using the referrer's unique coupon code. Additionally, the referrers are awarded with INR 1000 for first 50 successful invites.</li>
                                <li>Each invitee who joins using a valid referral coupon code receives INR 10 on successful onboarding. Additionally, the invitee receives 25 promotional credits to unlock the premium features of VaSi.</li>
                                <li>The promotional credits are non-transferable, have no cash value, and can only be used within the VaSi platform.</li>
                            </ul>

                            <h3>Usage of Coupon Codes:</h3>
                            <ul>
                                <li>Coupon codes must be applied during the signup process by the new user to be deemed valid.</li>
                                <li>Each coupon code is valid for one-time use only and cannot be reused.</li>
                            </ul>

                            <h3>Payment:</h3>
                            <ul>
                                <li>Referral rewards will be credited to the referrer’s VaSi wallet immediately after the successful onboarding of the invitee.</li>
                                <li>All rewards are subject to applicable taxation under Indian tax laws.</li>
                            </ul>

                            <h3>Termination and Changes:</h3>
                            <ul>
                                <li>VaSi reserves the right to modify, suspend, or terminate the Refer & Earn program at any time without prior notice, at its sole discretion.</li>
                            </ul>

                            <h3>Prohibited Activities:</h3>
                            <ul>
                                <li>Participants engaged in fraudulent activities, such as creating multiple accounts or employing automated systems for referrals, will be disqualified.</li>
                                <li>Rewards obtained through fraudulent means will be considered invalid and may lead to account suspension.</li>
                            </ul>

                            <h3>Liability:</h3>
                            <ul>
                                <li>VaSi is not liable for errors in the entry of information by users, technical malfunctions, or the failure of third parties to comply with the Refer & Earn program terms.</li>
                            </ul>

                            <h3>Governing Law:</h3>
                            <ul>
                                <li>These terms are governed by and construed in accordance with the laws of India.</li>
                            </ul>


                        </div>


                        <div className="margin-top-10 page-font text-white">
                            <h3>FAQs:</h3>
                            {faqData.map((faq, index) => (
                                <div key={index} className="faq-item">
                                    <div
                                        className={`text-start faq-question ${expandedIndex === index ? 'expanded' : ''}`}
                                        onClick={() => toggleExpand(index)}
                                    >
                                        <div className='float-left width-95 page-font-weight'>
                                            {faq.question}
                                        </div>

                                        <div className="guidlines-faq text-end float-right-faq-icon">{expandedIndex === index ? <i className="fa fa-chevron-up" aria-hidden="true"></i> : <i className="fa fa-chevron-down" aria-hidden="true"></i>}</div>
                                    </div>
                                    {expandedIndex === index &&
                                        <p className="faq-answer" dangerouslySetInnerHTML={{ __html: faq.answer }}></p>
                                    }
                                </div>
                            ))}
                        </div>

                    </div>
                </aside>
            </div>
            {/* <Footer /> */}
        </div>
    );
}

export default ReferAndEarnTOS;