import React from 'react';
import { Toast } from 'react-bootstrap';
function AdminToastAlert(props) {

  // const [showA, ] = useState(props.toastShow);
  // console.log(showA)
  //const toggleShowA = () => setShowA(!props.toastShow);
  //console.log(props)
  return (

    <Toast show={props.toastShow} onClose={props.toastClose} bg={props.className}>
      <Toast.Header>
        <strong className="me-auto">Alert</strong>
      </Toast.Header>
      <Toast.Body className="text-color-white">{props.content}</Toast.Body>
      {props.isNotifyUser &&
        <div className='alert-button-wraper'>
          <p className="text-white">
            Note: By clicking the "Notify User" button, user's PayPal account will be removed from our database. This action is irreversible.
          </p>
          <button type="button" className={"alert-button-notify " + (props.isNotifyButtonDisabled ? 'loader-btn' : '')} onClick={() => { props.notifyUser() }}>Notify User</button>
        </div>
      }
    </Toast>

    // <div show = {props.toastShow} className="toast align-items-center text-bg-primary border-0 top-center" role="alert" aria-live="assertive" aria-atomic="true">
    //     <div className="d-flex">
    //         <div className="toast-body">
    //             Hello, world! This is a toast message.
    //         </div>
    //         <button type="button" className="btn-close btn-close-white me-2 m-auto" data-coreui-dismiss="toast" aria-label="Close"></button>
    //     </div>
    // </div>
  );
}

export default AdminToastAlert;