import React, { useEffect } from 'react';
import ReactGA from "react-ga4";
import NavBar from './NavBar'

import '../assets/css/home_new.css';
import '../assets/css/home.css';
import '../assets/css/animation.css'
import '../assets/css/landingpage.css';
import LandingPageFooter from './LandingPageFooter';
import DotsImagesMobileCarousel from './BlurredImages';



import thumbnail_page_5_img_1 from '../assets/images/landing-page/1st.png';
import thumbnail_page_5_img_2 from '../assets/images/landing-page/2nd.png';
import thumbnail_page_5_img_3 from '../assets/images/landing-page/3rd.png';
import thumbnail_page_5_img_4 from '../assets/images/landing-page/4th.png';
import thumbnail_page_5_img_5 from '../assets/images/landing-page/5th.png';
import thumbnail_page_5_img_6 from '../assets/images/landing-page/6th.png';




import TextSlider from './TextSlider';

// App Component
function DownloadsPage() {

    useEffect(() => {
        const navbar = document.querySelector('.navbar');
        if (navbar) {
            navbar.classList.remove('hidden');
        }
    }, []);


    const handleButtonClick = () => {
        // Track the button click event
        ReactGA.event({
          category: "Button",
          action: "Download Now",
          label: "VaSi App Link",
        });
      };



        // Array of words to replace "iPhone"
        const words = ['iPhone', 'Trip', 'Outfit', 'Gadget', 'Kicks'];
        const interval = 3000;
        const duration = 2000;


        return (
            <div className="askvasi-home landing-page" id='askvasi-landing' style={{
                height: '100vh',
                display: 'flex'

            }}>
                {/* Navbar */}
                <NavBar />

                {/* Page 6 and beyond */}
                <div className="page-wrapper slide-up-overlay-slide-6 page-6 active" id="page-6">
                    {/* Container for the two columns */}
                    <div className="hero-section-container">
                        {/* Left Column: Overlay content */}
                        <div className="overlay-content-hero-section">

                            <div className='hero-title'><span className='hero-title-1'>Your next <TextSlider texts={words} interval={interval} duration={duration} /></span>
                                {/* <span className={`fade-in-title fade ${fadeIn ? 'in' : 'out'}`}>{words[currentWordIndex]}</span> */}
                                <br></br>is on us, Just share
                                <br></br>your Wisdom</div>
                            <p>Share all your tips & tricks with us and earn!</p>
                            <a rel="noopener noreferrer" href="https://apps.apple.com/in/app/vasi-extend-your-mind/id1488476877">
                                <button onClick={handleButtonClick} className="cta-button">Download Now</button></a>
                        </div>

                        <DotsImagesMobileCarousel />


                        {/* Right Column: Blurred images container */}
                        <div className="blurred-images-container-hero-section">
                            <div className="blurred-image image-1">
                                <a target='_blank' rel='noopener noreferrer' href="https://learnee.page.link/gnZddAtLfTu7ARBj8"><img src={thumbnail_page_5_img_3} loading="lazy" alt="Blurred Dots 3" /></a>
                            </div>
                            <div className="blurred-image image-2">
                                <a target='_blank' rel='noopener noreferrer' href="https://learnee.page.link/GJe2WwtFNcHkCJM16"><img src={thumbnail_page_5_img_2} loading="lazy" alt="Blurred Dots 2" /></a>
                            </div>
                            <div className="blurred-image image-3">
                                <a target='_blank' rel='noopener noreferrer' href="https://learnee.page.link/EMda9rS37HdzxGaz8"><img src={thumbnail_page_5_img_1} loading="lazy" alt="Blurred Dots 1" /></a>
                            </div>

                            <div className="blurred-image image-4">
                                <a target='_blank' rel='noopener noreferrer' href="https://learnee.page.link/KiX3nHr7xamYw3qD6"><img src={thumbnail_page_5_img_4} loading="lazy" alt="Blurred Dots 4" /></a>
                            </div>
                            <div className="blurred-image image-5">
                                <a target='_blank' rel='noopener noreferrer' href="https://learnee.page.link/iyXHC1Yapi9A33vq7"><img src={thumbnail_page_5_img_5} loading="lazy" alt="Blurred Dots 5" /></a>
                            </div>
                            <div className="blurred-image image-6">
                                <a target='_blank' rel='noopener noreferrer' href="https://learnee.page.link/o8sKaBqYFHyT3zk8A"><img src={thumbnail_page_5_img_6} loading="lazy" alt="Blurred Dots 6" /></a>
                            </div>
                        </div>

                    </div>



                    <LandingPageFooter />

                </div>


            </div>
        );
    }
    export default DownloadsPage;