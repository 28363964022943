import React, { useState, useEffect } from 'react';

const TextSlider = ({ texts, interval, duration }) => {

  const [currentTextIndex, setCurrentTextIndex] = useState(0);
  const [animationClass, setAnimationClass] = useState('');

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentTextIndex((prevIndex) => (prevIndex + 1) % texts.length);
    }, interval);

    return () => {
      clearInterval(intervalId);
    };
  }, [texts.length, interval]);

  useEffect(() => {
    setAnimationClass('slide-in');
    const timeoutId = setTimeout(() => {
      setAnimationClass('slide-out');
    }, duration);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [currentTextIndex, duration]);

  return (
   
      <span className={`text-slide fade-in-title ${animationClass}`}>
        {texts[currentTextIndex]}     
    </span>
    // <div className="text-slider-container">
    //   <div className={`text-slide ${animationClass}`}>
    //     {texts[currentTextIndex]}
    //   </div>
    // </div>
  );
};

export default TextSlider;
