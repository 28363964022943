import React from 'react';
import { Link } from "react-router-dom"
import Slider from "react-slick";
import placeholder_img from '../assets/images/no-image.jpg';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const SimilarDots = ({ similarDots }) => {
    console.log("similarDots==",similarDots);
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        lazyLoad: true,
        // swipeToSlide: true,
        arrows: false,
        gap: 10,
        responsive: [          
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 4,
                    centerPadding: '40px',
                },
            },
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 4,
                    centerPadding: '40px',
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                    centerPadding: '40px',
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    centerPadding: '20px'
                },
            },
            {
                breakpoint: 380,
                settings: {
                    slidesToShow: 2,
                    centerPadding: '10px'
                },
            },
        ]
    };
    return (
        <div className="similar-dots-container slider-container">
            <Slider {...settings}>
                {similarDots.map((dot, index) => (
                    <Link target='_blank' to={`/dots?mktDotId=${dot.mktPlaceId}`} key={index}>                        

                            {/* Check if dotMedia exists and has at least one item */}
                            {dot.payload.dotMedia && dot.payload.dotMedia.length > 0 && dot.payload.dotMedia[0].type === 4 ? (
                                <img className="similar-dot-image"
                                    src={dot.payload.dotMedia[0]?.thumbUrl}
                                    alt="Dot Thumbnail"
                                />
                            ) : (
                                <img className="similar-dot-image" src={placeholder_img} alt="Dot Thumbnail" />
                            )}
                            <div className="similar-dots-overlay">
                                <div className="similar-dot-title">{dot.dotTitle || "Untitled Dot"}</div>
                                <div className="similar-dot-creator">
                                    {dot.payload.userAvatar ? (<img className="similar-dot-user-avatar" src={dot.payload.userAvatar} alt="Dot Thumbnail" />
                                    ) : (
                                        <img className="similar-dot-user-avatar" src={placeholder_img} alt="Dot Thumbnail" />
                                    )}&nbsp; {dot.creator || "Unknown Creator"}
                                </div>
                            </div>


                            {/* 
                     <div className="similar-dot-card" key={index}>
                    <img className="similar-dot-image" src={dot.payload.dotMedia[0].thumbUrl} alt={dot.dotTitle} />
                    <div className="overlay">
                        <div className="dot-title">{dot.dotTitle}</div>
                        <div className="dot-creator">by {dot.creator}</div>
                    </div>
                </div>

                    <div className="dot-content">
                        <h4 className="dot-title">{dot.dotTitle || "Untitled Dot"}</h4>
                        <p className="dot-creator">By {dot.creator || "Unknown Creator"}</p>
                    </div> */}
                        
                    </Link>
                ))}
            </Slider>
        </div>
    );
};

export default SimilarDots;
