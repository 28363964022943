import React from 'react';
import { Link } from "react-router-dom"
import DashboardHeader from "./DashboardHeader"
import DashboardFooter from "./DashboardFooter"
import failedIcon from "../../assets/images/failed_icon.png"

function PaymentFailureAdmin() {
    return (
        <div className="wrapper d-flex flex-column min-vh-100 bg-light">
        <DashboardHeader></DashboardHeader>
            <div className="row">
                <div className="col-md-12 mx-auto mt-5 text-center">
                    <div className="payment_failure">
                        <div className="payment_header_failure">
                        <h1>Oops!</h1>
                        </div>
                        <div className="content_failure">                            
                            <div className="payment_check_failure_1"><img src={failedIcon} alt="..." /></div>
                            <p>Something went wrong while processing the payment!</p>
                            <Link className='btn btn-primary btn-color btn-width-auto' to="/">GO TO HOME</Link>
                        </div>

                    </div>
                </div>
            </div>
            <DashboardFooter></DashboardFooter>
        </div>

    );
}

export default PaymentFailureAdmin;