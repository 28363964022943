import Axios from "axios"
import { atom, selector } from "recoil";
export const adminMarketplaceListState = atom({
    key: "adminMarketplaceListState",
    default: [],
});


export const adminMarketplaceQuery = selector({
    key: 'adminMarketplaceQuery',
    get: async ({ get }) => {
        try {
            const searchKey = get(adminMarketplaceSearchKeyState)
            const filterKey = get(adminMarketplaceFilterKeyState)
            console.log(filterKey);
            const ourRequest = Axios.CancelToken.source()
            const pageSize = process.env.REACT_APP_PAGESIZE
            const URL = process.env.REACT_APP_BACKENDURL + 'initiateMarketplaceSearch';
            Axios.defaults.headers = {
                Authorization: 'Bearer ' + localStorage.getItem('userAuth')
            }
            const response = await Axios.post(URL, {
                data: {
                    "query": searchKey,
                    "pageOffset": 0,
                    "pageSize": pageSize,
                    "sortBy": "createdAt",
                    "isPopular": filterKey
                },
            },
                { cancelToken: ourRequest.token });
            return response.data;
        } catch (e) {
            return e
        }

    }
});


export const adminMarketplaceElementState = atom({
    key: "adminMarketplaceElementState",
    default: '',
});

export const adminMarketplaceSearchKeyState = atom({
    key: "adminMarketplaceSearchKeyState",
    default: '',
});

export const adminMarketplaceFilterKeyState = atom({
    key: "adminMarketplaceFilterKeyState",
    default: '',
});

export const adminMarketplaceButtonState = atom({
    key: "adminMarketplaceButtonState",
    default: '',
});

export const adminMarketplacePageOffesetState = atom({
    key: "adminMarketplacePageOffesetState",
    default: '20',
});