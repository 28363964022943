import React from "react"
import { useLocation } from "react-router-dom"
//import SidebarLeft from "./SidebarLeft"
//import SidebarRight from "./SidebarRight"
//import { UseAuth } from "../hooks/useAuth";
import {isMobile} from 'react-device-detect';
//import bodyBgIcon_1 from "../assets/images/body-bg-1.png"
//import bodyBgIcon_2 from "../assets/images/body-bg-2.png"


function Container(props) {
  const location = useLocation();
  //const auth = UseAuth();
  //console.log(location.pathname);
  var containerClass = '';
  var mainContainerClass = '';
  if(location.pathname.match('/search/')){
    containerClass = 'container search-margin  mt-h1 mb-5';    
  }else if(location.pathname.match('/user-profile')){
     if(isMobile){
      containerClass = 'col-md-9 col-sm-12 mb-5 margin-auto';
     }else{
      containerClass = 'col-md-8 col-sm-12 mb-5 margin-auto';
     }
    
     mainContainerClass = 'userProfileHeader'
  }else{
     containerClass = 'margin-auto col-md-8 col-sm-12 mt-h1 mb-5';
  }

  return (
    <div className={`container ${mainContainerClass}`}>
      {/* <img className="bg-icon-1" alt='' src={bodyBgIcon_1} />
      <img className="bg-icon-2" alt='' src={bodyBgIcon_2} /> */}
     
      <div className="col">
      {/* {auth.user && 
        <SidebarLeft />
      } */}

        <div className={`${containerClass}`}>
          <div className={"" + (props.wide ? "" : "container--narrow")}>{props.children}</div>
        </div>
        {/* {!location.pathname.match('/search/') &&  auth.user && !isMobile &&
          <SidebarRight />
        } */}
      </div>
    </div>
  )
}

export default Container