import React from "react"
//import { Link } from "react-router-dom"

function FooterNew() {
    const currentYear = new Date().getFullYear();
  return (
    <div id="footer-sb">
                <div className="container pt-4 pb-4">
                   
                        <div className="col-xs-12 with-border text-center">
                            {/* <div className="logo-sb mb-4">
                                <img width="220" height="34" src="data:image/svg+xml,%3Csvg%20xmlns='http://www.w3.org/2000/svg'%20viewBox='0%200%20220%2034'%3E%3C/svg%3E" className="img img-responsive" alt="Logo Vasi" />
                            </div> */}
                            <div className="mb-4 text-color-white">
                              
                                Copyright © {currentYear}  Learnee, Inc. All Rights Reserved
                              
                            </div>
                            <div className="networks">
                                <a href="https://twitter.com/VaSi_Ai" rel="noreferrer" target="_blank" aria-label="Vasi on Twitter">
                                    <i className="fab fa-twitter"></i>
                                </a>
                                <a className="ms-3" href="https://www.facebook.com/profile.php?id=100093084038569" rel="noreferrer" target="_blank" aria-label="Vasi on Facebook">
                                    <i className="fab fa-facebook"></i>
                                </a>
                                <a className="ms-3" href="https://www.linkedin.com/in/vasi-ai-301267272/" rel="noreferrer" target="_blank" aria-label="Vasi on LinkedIn">
                                    <i className="fab fa-linkedin"></i>
                                </a>
                                <a className="ms-3" href="https://www.instagram.com/vasi.ai/" rel="noreferrer" target="_blank" aria-label="Vasi on Instagram">
                                    <i className="fab fa-instagram"></i>
                                </a>
                                <a className="ms-3" href="https://www.youtube.com/channel/UCCkbhfPA7AjVh8VcR9SmuWg" rel="noreferrer" target="_blank" aria-label="Vasi on YouTube">
                                    <i className="fab fa-youtube"></i>
                                </a>
                            </div>

                        </div>
                        {/* <div className="col-xs-12 col-lg-auto col-networks p-4">
                            <p className="list-title mb-1">
                                Supported Networks
                            </p>
                            <div className="list-group mb-4">
                                <a href="/facebook/" className="list-group-item list-group-item-action" aria-current="true">
                                    <span className="social-icon facebook"><i className="fab fa-facebook-f"></i></span> Facebook
                                </a>
                                <a href="/twitter/" className="list-group-item list-group-item-action" aria-current="true">
                                    <span className="social-icon twitter"><i className="fab fa-twitter"></i></span> Twitter
                                </a>
                                <a href="/instagram/" className="list-group-item list-group-item-action" aria-current="true">
                                    <span className="social-icon instagram"><i className="fab fa-instagram"></i></span> Instagram
                                </a>
                                <a href="/linkedin/" className="list-group-item list-group-item-action" aria-current="true">
                                    <span className="social-icon linkedin"><i className="fab fa-linkedin-in"></i></span> LinkedIn
                                </a>

                            </div>



                        </div> */}
                        {/* <div className="col-xs-12 col-lg-auto col-knowledge p-4">
                            <p className="list-title mb-1">
                                Tool Knowledge
                            </p>
                            <div className="list-group mb-4">
                                <a href="#" target="_blank" rel="noreferrer" className="list-group-item list-group-item-action" aria-current="true">
                                    Help Documentation
                                </a>
                                <a href="#" className="list-group-item list-group-item-action" aria-current="true">
                                    Webinars
                                </a>
                                <a href="#" target="_blank" rel="noreferrer" className="list-group-item list-group-item-action" aria-current="true">
                                    What's New
                                </a>
                                <a href="#" className="list-group-item list-group-item-action" aria-current="true">
                                    Roadmap
                                </a>
                            </div>



                        </div>
                        <div className="col-xs-12 col-lg-auto p-4">
                            <p className="list-title mb-1">
                                Testimonials
                            </p>
                            <div className="list-group mb-4">
                                <a href="#" className="list-group-item list-group-item-action" aria-current="true">
                                    Solopreneurs
                                </a>
                                <a href="#" className="list-group-item list-group-item-action" aria-current="true">
                                    Small Businesses
                                </a>

                            </div>

                        </div>
                        <div className="col-xs-12 col-lg-auto p-4">
                            <p className="list-title mb-1">
                                Vasi
                            </p>
                            <div className="list-group mb-4">
                                <a href="/pricing/" className="list-group-item list-group-item-action" aria-current="true">
                                    Pricing
                                </a>
                                <a href="#" target="_blank" rel="noreferrer" className="list-group-item list-group-item-action" aria-current="true">
                                    App Status
                                </a>
                                <a href="/about-us/" className="list-group-item list-group-item-action" aria-current="true">
                                    About Us
                                </a>                               
                            </div>

                        </div> */}
                    
                </div>
            </div>
  )
}

export default FooterNew