import React, { useState, useEffect } from 'react';
import Axios from "axios";
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { useParams, useLocation } from "react-router-dom"
//import { Link } from "react-router-dom"
import DashboardHeader from "./DashboardHeader"
import DashboardFooter from "./DashboardFooter"
//import AdminToastAlert from "./AdminToastAlert"
import { formatAMPM, convertUTCDateToLocalDate } from "../utils"
import defaultAvatar from "../../assets/images/mystery-man.jpg"
import prohibited_icon from '../../assets/images/icons8-prohibited-48.png'
//import avartar3 from '../../assets/images/avatars/3.jpg'
import LoadingDotsIcon from "../LoadingDotsIcon"

function DashboardUserProfile(pops) {
    const { userId } = useParams();
    const location = useLocation()
    //const [isButtonDisabled, setButtonDisabled] = useState({});
    // const [showToast, setShowToast] = useState(false);
    // const [content, setContent] = useState("");
    // const [className, setClassName] = useState("");

    const [userData, setUserData] = useState("");
    const [payoutList, setPayoutList] = useState('')

    const [payoutSchedule, setPayoutSchedule] = useState("");
    const [requiremrnts, setRequiremrnts] = useState("");
    const [isLoading, setIsLoading] = useState(true)

    // const payout_URL = process.env.REACT_APP_BACKENDURL + 'stripeConnectPayout';
    // const Success_URL = process.env.REACT_APP_HOMEURL + 'dashboard/profile/' + userId;
    // const Cancel_URL = process.env.REACT_APP_HOMEURL + 'dashboard/profile/' + userId;
    //console.log(location.state);

    useEffect(() => {
        const profile_URL = process.env.REACT_APP_BACKENDURL + 'connectUserAccountDetails';
        const payout_pending_request_URL = process.env.REACT_APP_BACKENDURL + 'payoutPendingRequests';
        if (location.state !== null) {
            //console.log(location.state.userId)
            localStorage.setItem(location.state.userId, JSON.stringify(location.state));
        }

        //console.log(JSON.parse(localStorage.getItem(userId)));

        const payoutPendingRequests = async (userId) => {
            const ourRequest = Axios.CancelToken.source();
            setIsLoading(true);
            Axios.defaults.headers = {
                Authorization: 'Bearer ' + localStorage.getItem('userAuth')
            }
            try {
                const response = await Axios.post(payout_pending_request_URL, {
                    data: {
                        "user_id": userId,
                        "status": 55
                    }
                },
                    { cancelToken: ourRequest.token }
                );
                const json = await response.data;
                console.log(json.result)
                if (json.result.status === 200 || json.result.status === 400) {
                    console.log(json.result)
                    setPayoutList(json.result);
                    setIsLoading(false);
                } else {
                    setIsLoading(false);
                }
            } catch (er) {
                console.log(er.response.status)
                if (er.response.status === 401) {
                    localStorage.clear();
                    window.location.href = process.env.REACT_APP_HOMEURL + 'login'
                }
                setIsLoading(false);
                console.log("There was a problem or the request was cancelled." + er)
            }

        }
        //console.log(JSON.parse(localStorage.getItem('profile')).payoutAccountId);
        /*Stripe user account details*/
        const userDetails = async () => {
            const ourRequest = Axios.CancelToken.source();
            try {
                const response = await Axios.post(profile_URL, {
                    data: {
                        "account_id": JSON.parse(localStorage.getItem(userId)).payoutAccountId,//"acct_1M29KMPHc62GRNAz",
                        "user_id": userId,
                    }
                },
                    { cancelToken: ourRequest.token }
                );
                const json = await response.data;
                //console.log(json.result)
                if (json.result.status === 200) {
                    setUserData(json.result.data)
                    setPayoutSchedule(json.result.data.settings.payouts.schedule);
                    setRequiremrnts(json.result.data.requirements.currently_due);
                } else {

                }
            } catch (er) {
                console.log("There was a problem or the request was cancelled." + er)
            }

        }
        payoutPendingRequests(userId);

        userDetails();

    }, [userId, location])


    /*Submit report for MarketPlace id API*/
    // const generatePayoutLink = async (data, id) => {
    //     //e.preventDefault();
    //     const ourRequest = Axios.CancelToken.source();
    //     setButtonDisabled({ [id]: true });
    //     //console.log(isButtonDisabled);
    //     //setButtonDisabled(true)
    //     console.log("Data====", data);
    //     try {
    //         const response = await Axios.post(payout_URL, {
    //             data: {
    //                 "amount": data.amount,
    //                 "currency": "usd",
    //                 "account_id": data.payoutAccountId,
    //                 "user_id": data.userId,
    //                 "success_URL": Success_URL,
    //                 "cancel_URL": Cancel_URL,
    //                 "transaction_id": data.transactionId
    //             }
    //         },
    //             { cancelToken: ourRequest.token }
    //         );
    //         const json = await response.data;
    //         console.log(json.result.session_url)
    //         if (json.result.status === 201) {
    //             setButtonDisabled({ [id]: false });
    //             window.location = json.result.session_url;
    //         } else {
    //             setClassName('danger');
    //             setContent(json.result.message)
    //             setShowToast(true)
    //             setButtonDisabled({ [id]: false });
    //         }
    //     } catch (er) {
    //         setClassName('danger');
    //         setContent("There was a problem or the request was cancelled.")
    //         setShowToast(true)
    //         setButtonDisabled({ [id]: false });
    //         //console.log("There was a problem or the request was cancelled." + er)
    //     }

    //     //console.log(showToast)

    // }

    // const handleClose = () => {
    //     setShowToast(false);
    // }

    return (
        <div className="wrapper d-flex flex-column min-vh-100 bg-light">
            <DashboardHeader></DashboardHeader>
            <section className="section profile body flex-grow-1 px-3">
                <div className="row">
                    <div className="col-xl-4">
                        <div className="card">
                            <div className="card-body profile-card pt-4 d-flex flex-column align-items-center">
                                {JSON.parse(localStorage.getItem(userId)) &&
                                    <img src={typeof JSON.parse(localStorage.getItem(userId)).avatar === 'undefined' || JSON.parse(localStorage.getItem(userId)).avatar === '' || JSON.parse(localStorage.getItem(userId)).avatar === null ? defaultAvatar : JSON.parse(localStorage.getItem(userId)).avatar} alt="Profile" className="avatar-img-200" />
                                }
                                {JSON.parse(localStorage.getItem(userId)) &&
                                    <h2 className="text-center">{typeof JSON.parse(localStorage.getItem(userId)).userName === 'undefined' || JSON.parse(localStorage.getItem(userId)).userName === null ? 'Anonmous' : JSON.parse(localStorage.getItem(userId)).userName}</h2>
                                }
                                {payoutList &&
                                    <>
                                        <h4 className="text-center">Available Credits: {payoutList.availableCredits}</h4>
                                        <h4 className="text-center">Total Redeemed: {payoutList.totalRedeemed}</h4>
                                    </>
                                }

                            </div>
                        </div>
                    </div>
                    <div className="col-xl-8">
                        <div className="card">
                            <div className="card-body pt-3">

                                <div className="tab-content pt-2">
                                    <div className="tab-pane fade profile-overview active show" id="profile-overview" role="tabpanel">

                                        <h5 className="card-title">Account information</h5>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-4 label ">Account Id</div>
                                            <div className="col-lg-9 col-md-8">{userData.id}</div>
                                        </div>
                                        {userData.email &&
                                            <div className="row">
                                                <div className="col-lg-3 col-md-4 label ">Email</div>
                                                <div className="col-lg-9 col-md-8">{userData.email}</div>
                                            </div>
                                        }
                                        <div className="row">
                                            <div className="col-lg-3 col-md-4 label">Account Type</div>
                                            <div className="col-lg-9 col-md-8">{userData.type}</div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-3 col-md-4 label">Details Submitted</div>
                                            <div className="col-lg-9 col-md-8">{userData.details_submitted === true ? 'Yes' : 'No'}
                                                &nbsp;
                                                {!userData.details_submitted &&
                                                    <OverlayTrigger
                                                        delay={{ hide: 450, show: 300 }}
                                                        overlay={(props) => (
                                                            <Tooltip {...props}>
                                                                The account owner needs to provide more information to Stripe to enable payments and payouts on this account.
                                                                <div style={{ padding: "10px" }}>
                                                                    <ul>
                                                                        {requiremrnts.length > 0 &&
                                                                            requiremrnts.map((data, index) => {
                                                                                return (
                                                                                    <li key={index}>{data}</li>
                                                                                )

                                                                            })
                                                                        }
                                                                    </ul>
                                                                </div>
                                                            </Tooltip>
                                                        )}
                                                        placement="bottom"
                                                    ><i><img height="16px" width="16px" alt="pro" src={prohibited_icon} /></i>
                                                    </OverlayTrigger>
                                                }
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-3 col-md-4 label">Payout Enabled</div>
                                            <div className="col-lg-9 col-md-8">{userData.payouts_enabled === true ? 'Yes' : 'No'}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-4 label">Charges Enabled</div>
                                            <div className="col-lg-9 col-md-8">{userData.charges_enabled === true ? 'Yes' : 'No'}</div>
                                        </div>


                                        <div className="row">
                                            <div className="col-lg-3 col-md-4 label">Payout schedule</div>
                                            <div className="col-lg-9 col-md-8">{payoutSchedule.interval} — {payoutSchedule.delay_days} day rolling basis</div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-3 col-md-4 label">Country</div>
                                            <div className="col-lg-9 col-md-8">{userData.country}</div>
                                        </div>
                                        <div className="row">
                                            <div className="col-lg-3 col-md-4 label">Currency</div>
                                            <div className="col-lg-9 col-md-8">{userData.default_currency}</div>
                                        </div>
                                    </div>
                                </div>
                                <br />
                                <h5 className="card-title">Completed Payouts</h5>
                                <div className="table-responsive">
                                    {isLoading &&
                                        <LoadingDotsIcon />
                                    }

                                    {typeof payoutList.payouts === 'undefined' && !isLoading &&
                                        <strong>No past payouts!</strong>
                                    }

                                    {typeof payoutList.payouts !== 'undefined' &&
                                        <table className="table border mb-0">
                                            <thead className="table-light fw-semibold">
                                                <tr className="align-middle">
                                                    <th className="text-center">Payout Requested On</th>
                                                    <th className="text-center">Payout Completed On</th>
                                                    <th className="text-center">Payout Amount</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                           
                                                <tbody>
                                                    {payoutList.payouts.map((item, index) => (
                                                        <tr className="align-middle" key={index}>

                                                            <td className="text-center">
                                                            {formatAMPM(convertUTCDateToLocalDate(new Date(item.createdAt)))}
                                                               

                                                            </td>
                                                            <td className="text-center">
                                                            {formatAMPM(convertUTCDateToLocalDate(new Date(item.updatedAt)))}

                                                            </td>
                                                            <td className="text-center">
                                                                <strong>${item.amount}</strong>
                                                            </td>

                                                            <td>
                                                                <div className="dropdown">
                                                                    <strong style={{ color: '#008000' }}>Completed</strong>
                                                                    {/* <button onClick={() => generatePayoutLink(item, index)} className={"btn p-2 btn-primary " + (isButtonDisabled[index] ? 'loader-btn' : '')} type="button" data-coreui-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                                    Pay
                                                                </button> */}

                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))
                                                    }
                                                </tbody>
                                           


                                        </table>
                                    }
                                </div>

                                {/* <br />
                                <h5 className="card-title">Complete Payouts</h5>
                                <div className="table-responsive">

                                    <table className="table border mb-0">
                                        <thead className="table-light fw-semibold">
                                            <tr className="align-middle">
                                                <th className="text-center">
                                                    <i className="icon fa fa-users" aria-hidden="true"></i>

                                                </th>
                                                <th>Name</th>
                                                <th className="text-center">Country</th>
                                                <th>Paid On</th>
                                                <th className="text-center">Payout Amount</th>
                                                <th>Action</th>
                                                <th></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr className="align-middle">
                                                <td className="text-center">
                                                    <div className="avatar avatar-md"><img className="avatar-img" src={avartar3} alt="user@email.com" /><span className="avatar-status bg-success"></span></div>
                                                </td>
                                                <td>
                                                    <div>Kevin Anderson</div>
                                                    <div className="small text-medium-emphasis"><span>New</span> | Requested On: Jan 1, 2020</div>
                                                </td>
                                                <td className="text-center">
                                                    <i className="icon icon-xl fa fa-flag" aria-hidden="true"></i>
                                                </td>

                                                <td className="text-center">
                                                    Jan 1, 2020
                                                </td>

                                                <td className="text-center">
                                                    $500
                                                </td>

                                                <td>
                                                    <div className="dropdown">
                                                        <button className="btn btn-transparent p-0" type="button" data-coreui-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            More Details
                                                        </button>

                                                    </div>
                                                </td>
                                            </tr>
                                            <tr className="align-middle">
                                                <td className="text-center">
                                                    <div className="avatar avatar-md"><img className="avatar-img" src={avartar3} alt="user@email.com" /><span className="avatar-status bg-danger"></span></div>
                                                </td>
                                                <td>
                                                    <div>Kevin Anderson</div>
                                                    <div className="small text-medium-emphasis"><span>Recurring</span> | Requested On: Jan 1, 2020</div>
                                                </td>
                                                <td className="text-center">
                                                    <i className="icon icon-xl fa fa-flag" aria-hidden="true"></i>
                                                </td>
                                                <td className="text-center">
                                                    Jan 1, 2020
                                                </td>
                                                <td className="text-center">
                                                    $500

                                                </td>

                                                <td>
                                                    <div className="dropdown">
                                                        <button className="btn btn-transparent p-0" type="button" data-coreui-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            More Details
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>


                                        </tbody>

                                    </table>

                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* {showToast &&
                <AdminToastAlert toastClose={handleClose} toastShow={showToast} content={content} className={className}></AdminToastAlert>
            } */}
            <DashboardFooter></DashboardFooter>
        </div>
    );
}

export default DashboardUserProfile;