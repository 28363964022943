import React, { useState } from 'react'
import '../../assets/css/guidlines.css'


import Gift from "../../assets/images/love-letter.gif"
import Winner from "../../assets/images/winner_2.png"
//import FloatingImg from "../../assets/images/animated-love-heart_100.gif"
//import CelebrationEmojis from '../CelebrationEmojis'

function GuidelinesAndProcedure() {
    // FAQ data
    const faqData = [

        {
            question: 'What kind of Dots can I create for the Holiday Contest?',
            answer: 'Share your best love lessons or stories in the Dot: Reflect on what love has taught you, your key relationship mantras, advice for all love stages, or a personal love story that shines.',
        },

        {
            question: 'How will I receive the prize if I win?',
            answer: 'The prize will be sent to the winner via the email/mobile associated with their VaSi account.',
        },

        {
            question: 'Can I submit more than one Dot for the contest?',
            answer: 'Definitely! You\'re welcome to submit as many Dots as you\'d like. We\'re excited to see the variety of perspectives and lessons you have to share.',
        },

        {
            question: 'Is there any entry fee for participating?',
            answer: 'No, participation is absolutely free.',
        },

        {
            question: 'How will the winner be determined?',
            answer: 'The winner will be the Dot with the highest number of purchases on the Marketplace.',
        },

        {
            question: 'When will the contest end?',
            answer: 'The Valentine contest is open until February 14th, 2024. Join in and win something special to make your partner\'s Valentine\'s Day even more memorable.',
        },

    ]


    const [expandedIndex, setExpandedIndex] = useState(0);

    const toggleExpand = (index) => {
        setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
    };

    return (
        <div className="emojis-overlay">
            <div className="container">
                <div className="row justify-content-center"> {/* Center content */}
                    <div className="col-lg-8"> {/* Adjust column width as needed */}
                        <div className="outer-page guidlines">
                            <aside className="inner_pages">
                                <div className="px-0">

                                    {/* <div className="ice-container">
                                        <img alt="Gift" src={Gift} />
                                    </div> */}


                                    <div className="row">
                                        <div className="col text-start page-title-guid coloured-title margin-top-30">How to participate
                                            <span className="border-line"></span></div>
                                        <div className="col text-end">
                                            <img width={120} alt="Gift" src={Gift} />

                                        </div>
                                    </div>

                                    <p>Make your love story unforgettable! Join the <span className='page-font-weight'>#vasi #challenge #valentine</span> now! 💑 🎁</p>

                                    <ul className='page-font margin-top-1rem'>
                                        <li>
                                            <p><span className='page-font-weight'>Write a Dot</span> about journey of love, pets, delicious food, enchanting places, and special people.</p>

                                        </li>
                                        <li>
                                            <p>
                                                <span className='page-font-weight'>Share it</span> on our <span className='page-font-weight'>Marketplace</span>.
                                            </p>

                                        </li>
                                        <li>
                                            <p>
                                                Use our Hashtags- <span className='page-font-weight'>#valentine</span> because hashtags are your magic wand.
                                            </p>

                                        </li>
                                        <li>
                                            <p>
                                                <span className='page-font-weight'>The more purchases</span> your Dot receives, higher the chances of winning!! 🚀💕
                                            </p>

                                        </li>
                                    </ul>

                                    <div className='page-font'>
                                        Stand a chance to win vouchers worth <span className='page-font-weight'>up to $100 </span> for the top three winners.
                                    </div>

                                    <div className="row justify-content-center">
                                        <div className="col-auto">
                                            <img alt="Winner" src={Winner} />
                                        </div>
                                    </div>


                                    <div className='page-font'>
                                        <strong>FAQ</strong>
                                    </div>


                                    <div className="margin-top-10 page-font">
                                        {faqData.map((faq, index) => (
                                            <div key={index} className="faq-item">
                                                <div
                                                    className={`text-start faq-question ${expandedIndex === index ? 'expanded' : ''}`}
                                                    onClick={() => toggleExpand(index)}
                                                >
                                                    <div className='float-left width-95 page-font-weight'>
                                                        {faq.question}
                                                    </div>

                                                    <div className="guidlines-faq text-end float-right-faq-icon">{expandedIndex === index ? <i className="fa fa-chevron-up" aria-hidden="true"></i> : <i className="fa fa-chevron-down" aria-hidden="true"></i>}</div>
                                                </div>
                                                {expandedIndex === index &&
                                                    <p className="faq-answer" dangerouslySetInnerHTML={{ __html: faq.answer }}></p>
                                                }
                                            </div>
                                        ))}
                                    </div>

                                </div>
                            </aside>
                        </div>

                    </div>

                </div>

                {/* <CelebrationEmojis /> */}

            </div>
        </div>
    );
}

export default GuidelinesAndProcedure;
