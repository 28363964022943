import React, { useEffect } from 'react';
//import HeaderLoggedOut from "../HeaderLoggedOut"
//import FooterNew from "../FooterNew"
import { Link } from "react-router-dom"
import NavBar from '../NavBar';
import LandingPageFooter from '../LandingPageFooter';
//import Page from "./Page"
function PrivacyPolicyIndia() {
    useEffect(() => {
        document.title = `Privacy Policy India | VasiApp`
        window.scrollTo(0, 0)
    }, [])
    return (
        // <div className=" home inner_pages_nav elementor-default elementor-kit-6677 elementor-page elementor-page-4722">
        <div className="askvasi-home">
            <NavBar />
            <div className="container outer-page">
                <header className="masthead_inner">
                    <div className="container px-5 text-center">
                        <p>If you live in the United States or Canada, you are agreeing to the <Link to="/privacy-policy-usa">Learnee Terms of Service</Link>.</p>
                        <p>If you live in India, you are agreeing to the <Link to="/privacy-policy-india">Learnee India Terms of Service</Link>.</p>
                        <h1>Learnee India Privacy Policy</h1>
                        <p>(If you live in India)</p>
                    </div>
                </header>
                <aside className="inner_pages">
                    <div className="container px-5">
                        <div className="row gx-5">
                            <p>Last Updated: March 18, 2022.</p>
                            <p>This Privacy Policy (<strong>“Policy”</strong>) describes the manner in which Learnee, Inc. (<strong>“Learnee”</strong>, <strong>“we,”</strong> or <strong>“us”</strong>) and our reminder and content sharing tool, along with our related mobile application (<strong>“VaSi”</strong>) and our website at <a rel="noreferrer" href="https://www.learnee.ai" target='_blank'>www.learnee.ai</a> (the “Site”) (collectively, the “Service”) use, share, and collect data from individuals who use the Service (<strong>“Users”</strong>) and their devices.  By using the Service, you agree to this Policy, which is incorporated into and is subject to the Learnee Terms of Service.</p>
                            <p>Information We Collect.  We may collect a variety of information from or about you or your devices from various sources, as described below:</p>
                            <ul>
                                <li><p><strong>User-provided Information.</strong> When you register to use the Service, we may collect information about you, including your name, mobile phone number, geographic area, or preferences.  You may provide us with information in various ways on the Service.  For example, you may provide us with information when you use the Service to share a bundle of information consisting of photos, videos, images, text, data, audio recordings, or other information (each bundle, a <strong>“Dot”</strong>) with another user, upload a Dot on the Marketplace in the Service, or send us customer service-related requests.  If you contact us directly, we may receive additional information about you.  For example, when you contact us for support, we will receive your name, email address, the contents of a message or attachments that you may send us, and other information you choose to provide.  When we send you emails, we may track whether you open them to learn how to deliver a better customer experience and improve the Service.</p></li>



                                <li><p><strong>Your Contacts. </strong> When you use the Service, with your permission, the Service may also access and store names and contact information from your address book, as well as photo, video, and audio storage to facilitate social interactions that you initiate through the Service and for other purposes described in this Policy or at the time of consent or collection.</p></li>

                                <li><p><strong>Payment Information. </strong> If you make a purchase through the Service, such as on the Marketplace, your payment-related information, such as credit card or other financial information, may be collected by our third-party payment processor on our behalf. This information will not be stored by Learnee.</p></li>

                                <li> <p><strong>Information Collected from Cookies and Similar Technologies. </strong> When you use the Service, we and third-party partners may send one or more cookies – small text files containing a string of alphanumeric characters – to your device.  We may use both session cookies and persistent cookies.  A session cookie disappears after you close your browser.  A persistent cookie remains after you close your browser and may be used by your browser on subsequent visits to the Service.  Please review your web browser’s “Help" file to learn the proper way to modify your cookie settings.  Please note that if you delete or choose not to accept cookies from the Service, you may not be able to utilize the features of the Service to their fullest potential.</p></li>

                                <li><p><strong>Device Information. </strong> We automatically record certain information about the device and software you use to access the Service, including internet protocol (<strong>“IP”</strong>) address, web browser type, operating system version, phone carrier and manufacturer, application installations, device identifiers, mobile advertising identifiers, and push notifications.</p></li>


                                <li><p><strong>Usage Information. </strong> To help us understand how you use the Service and to help us improve the Service, we automatically receive information about your interactions with the Service, like the web pages or sites that you visit just before or just after you use the Service, the pages or other content you view or otherwise interact with on the Service, the dates and times that you visit, access, or use the Service, and other analytic information including through your use of the Service.</p></li>

                                <li><p><strong>Location Information.</strong>  When you use the Service, if you allow us, we receive your precise location.  We may also collect the precise location of your device when the app is running in the foreground or background and even when the app is closed.  We also infer the general physical location of your device and the geographic regions our Users come from.  For example, your IP address may indicate your general geographic region.</p></li>

                                <li><p><strong>User Feedback. </strong>  Users who buy Dots may rate and review their purchased Dots. We receive information about ratings and reviews and give Users information about ratings and reviews.</p></li>

                                <li><p><strong>Information We Receive from Third Parties. </strong>  We may receive additional information about you from other Users of the Service.  For example, a User may submit information, such as a phone number or other information, about others with the Service in connection with sharing Dots.   </p> </li>



                            </ul>
                            <p><strong>How We Use the Information We Collect. </strong> We use the information we collect on the Service in a variety of ways in providing the Service and operating our business, including the following or as otherwise may be disclosed to you at the time the information is collected: </p>
                            <ul>
                                <li>We use the information that we collect on the Service to operate, administer, maintain, enhance and provide all features of the Service (including to verify the mobile device on which you install VaSi), to provide services and information that you request, to respond to comments and questions and otherwise to provide support to Users.</li>
                                <li>We use the information that we collect on the Service to understand and analyze statistics and usage trends and preferences of our Users, to improve the Service, and to develop new products, services, features, and functionality.</li>
                                <li>We use the information we collect to contact you for administrative purposes, such as verifying your account when you register, for customer service or to send communications.</li>
                                <li>We use cookies and automatically collected information to: (i) personalize the Service, such as remembering information about you so that you will not have to re-enter it during your visit or the next time you visit the Service; (ii) monitor and analyze the effectiveness of the Service; (iii) monitor aggregate site usage metrics; and (iv) improve the Service.</li>
                                <li>We de-identify and aggregate information collected through the Service and use it for any lawful purpose.</li>
                                <li>We use the information we collect to find and prevent fraud, as well as to respond to trust and safety issues that may arise.</li>
                                <li>We use the information we collect for compliance purposes, including enforcing our legal rights and Terms of Service, or as may be required by applicable laws and regulations or requested by any judicial process or governmental agency.</li>
                                <li>We may use the information we collect to send you push notifications.</li>

                            </ul>
                            <p><strong>How We Share the Information We Collect. </strong> We may disclose information we collect from or about you as described below or otherwise disclosed to you at the time of collection:</p>
                            <ul>
                                <li><p><strong>At Your Direction. </strong>  Any information, including a Dot, that you direct VaSi to share with a third party will be available to such third party.</p></li>

                                <li><p><strong>Other Users. </strong>  Any information that you voluntarily choose to include in a publicly accessible area of the Service will be available to anyone who has access to that content, including other Users, by default.  Although this content is available to other Users by default, below in the “Your Choices” section of this Policy, we describe the controls that you can use, along with other relevant settings associated with your account, to limit the sharing of certain information.  We are not responsible for other Users’ use of available information, so you should carefully consider whether and what to make available to others or how you identify yourself on the Service. When you upload a Dot to the Marketplace or purchase a Dot on the Marketplace, other Users can view information about the creation and purchase history of Dots on the Marketplace, including who created the Dot, who purchased the Dot, when the Dot was purchased, and the price at which the Dot was purchased. </p></li>

                                <li> <p><strong>Vendors and Service Providers. </strong> We may work with third-party service providers and vendors to provide website, application development, hosting, analytics, maintenance, and other services for us. We may share any information we receive with contractors, vendors, service providers and other third parties retained in connection with the provision of the Service.</p></li>

                                <li><p><strong>Third-Party App Integrations. </strong>  If you connect a third-party application to store the content that you create on the Service, such as your iCloud or Google Drive account, we will share information such as your Dots with that third-party application.</p></li>

                                <li> <p><strong>Marketing.</strong> We do not rent, sell, or share information about you with nonaffiliated companies for their direct marketing purposes, unless we have your permission.</p></li>

                                <li><p><strong>As Required by Law. </strong>  We may disclose information about you if required to do so by law or in the good-faith belief that such action is necessary to comply with state and federal laws, in response to a court order, judicial or other government subpoena or warrant, or to otherwise cooperate with law enforcement or other governmental agencies.</p></li>

                                <li> <p><strong>Trust and Safety Concerns. </strong> We also reserve the right to disclose information about you that we believe, in good faith, is appropriate or necessary to: (i) take precautions against liability; (ii) protect ourselves or others from fraudulent, abusive, or unlawful uses or activity; (iii) investigate and defend ourselves against any third-party claims or allegations; (iv) protect the security or integrity of the Service and any facilities or equipment used to make the Service available; or (v) protect our property or other legal rights (including, but not limited to, enforcement of our agreements), or the rights, property, or safety of others.</p></li>

                                <li><p><strong>Merger, Sale, or Other Asset Transfers. </strong>  Information about our Users may be disclosed and otherwise transferred to an acquirer, successor, assignee, service providers, advisors, potential transactional partners, or other third parties in connection with the consideration, negotiation, or completion of any merger, acquisition, debt financing, sale of assets, or similar transaction, or in the event of an insolvency, bankruptcy, or receivership in which information is transferred to one or more third parties as one of our business assets. </p></li>

                                <li><p><strong>Consent. </strong>  We may also disclose information from or about you or your devices with your permission.</p></li>
                            </ul>
                            <p><strong>Your Choices</strong></p>
                            <ul>
                                <li><p><strong>Sharing Preferences. </strong>  You may, of course, decline to share certain information with us, in which case we may not be able to provide to you some of the features and functionality of the Service.  We provide you with settings to allow you to set your sharing preferences for content you post to the Service.  If you wish to amend or delete information we hold about you, you may contact us at <a rel="noreferrer" href="mailto:hello@learnee.ai">hello@learnee.ai</a>.</p></li>

                                <li> <p><strong>Location Information. </strong> You can prevent your device from sharing precise location information at any time through your device’s operating system settings.  However, location is core to the Service and without it, you may not be able to successfully use all functionalities of the Service.</p></li>

                                <li><p><strong>Contacts. </strong>  You may enable or disable the sharing of contacts information at any time through your device’s operating system settings.</p></li>

                                <li><p><strong>Do Not Track.</strong>  There is no accepted standard on how to respond to Do Not Track signals, and we do not process or respond to such signals.</p></li>
                            </ul>
                            <ul>
                                <li><p><strong>Third Parties </strong>

                                    The Service may contain links to other websites, products, or services that we do not own or operate.  We are not responsible for the privacy practices of these third parties.  Please be aware that this Policy does not apply to your activities on these third-party services or any information you disclose to these third parties.  We are not responsible for the privacy practices or the content of such sites.  If you have any questions about how these other sites use information from or about you, you should contact them directly.  We encourage you to read their privacy policies before providing any information to them.</p></li>

                                <li><p><strong>Children’s Privacy </strong>

                                    We do not knowingly collect, maintain, or use personal information from children under the age of 13, and no part of the Service is directed to children.  If you learn that a child has provided us with personal information in violation of this Policy, then you may alert us at <a rel="noreferrer" href="mailto:hello@learnee.ai">hello@learnee.ai</a>.</p></li>


                                <li><p><strong>Security </strong>

                                    We make reasonable efforts to protect your information by using certain physical, managerial, and technical safeguards that are designed to improve the security of information that we maintain.  Please be aware that as the Service is hosted electronically, we cannot and do not guarantee the security or privacy of your information.</p> </li>


                                <li><p><strong>International Visitors </strong>

                                    The Service is hosted in the United States and is intended for visitors located within the United States.  If you choose to use the Service from the European Union or other regions of the world with laws governing data collection and use that may differ from U.S. law, then please note that you are transferring information from or about you or your devices outside of those regions to the United States for storage and processing.  Also, we may transfer your data from the U.S. to other countries or regions in connection with storage and processing of data, fulfilling your requests, and operating the Service.  By providing any information, including information from or about you or your devices, on or to the Service, you consent to such transfer, storage, and processing. </p></li>


                                <li><p><strong>Changes and Updates to this Policy </strong>

                                    Please revisit this page periodically to stay aware of any changes to this Policy, which we may update from time to time.  If we modify this Policy, we will make it available through the Service, and indicate the date of the latest revision.  In the event we materially change the ways in which we use or share information from or about you or your devices previously collected from you through the Service, we will make reasonable efforts to notify you of the changes.  For example, we may generate a Dot or similar pop-up notification when you access the Service after such changes are made.  Your continued use of the Service after the revised Policy has become effective indicates that you have read, understood and agreed to the current version of this Policy. </p></li>


                                <p><strong>How to Contact Us</strong></p>

                                <li>Please contact us with any questions or comments about this Policy or our processing activities at <a rel="noreferrer" href="mailto:hello@learnee.ai">hello@learnee.ai</a>.
                                    <br></br>
                                    <div className='elementor-align-center'>
                                        Learnee, Inc.<br></br>
                                        3101 Park Blvd.<br></br>
                                        Palo Alto, CA 94306
                                    </div>
                                </li>
                            </ul>


                        </div>
                    </div>
                </aside>
            </div>
            <LandingPageFooter />
        </div>
    );
}

export default PrivacyPolicyIndia;
