import React from 'react';
import { Link } from "react-router-dom"
import DashboardHeader from "./DashboardHeader"
import DashboardFooter from "./DashboardFooter"
import successIcon from "../../assets/images/success_icon.png"

function PaymentSuccessAdmin() {

    // const handleRedirect = async (e) => {
    //     const redirect_url = localStorage.getItem('success_url');
    //     if (redirect_url) {
    //         window.location = redirect_url;
    //     } else {
    //         window.location = '/dashboard';
    //     }
    // }

    return (
        <div className="wrapper d-flex flex-column min-vh-100 bg-light">
            <DashboardHeader></DashboardHeader>
            <div className="row">
                <div className="col-md-12 mx-auto mt-5 text-center">
                    <div className="payment">
                        <div className="payment_header">
                            <h1>PAYMENT SUCCESS!</h1>
                        </div>
                        <div className="content padding-20">
                            <p>Congratulations! Your payment was successful.</p>
                            <div className="payment-check-1"><img src={successIcon} alt="..." /></div>
                            <p>Please open VaSi app to see and use this Dot.</p>
                            <Link className='btn btn-primary btn-color btn-width-auto' to="/">GO TO HOME</Link>
                        </div>
                    </div>
                </div>
            </div>
            <DashboardFooter></DashboardFooter>
        </div>

    );
}

export default PaymentSuccessAdmin;