import React from 'react';
import Slider from "react-slick";

import aboutUsImage1 from '../assets/images/about-us/Android Screen Size1.png'
import aboutUsImage2 from '../assets/images/about-us/Android Screen Size2.png'
import aboutUsImage3 from '../assets/images/about-us/Android Screen Size3.png'
import aboutUsImage4 from '../assets/images/about-us/Android Screen Size4.png'
import aboutUsImage5 from '../assets/images/about-us/Android Screen Size5.png'
import aboutUsImage6 from '../assets/images/about-us/Android Screen Size6.png'

const AboutUsCarousel = () => {


    const items = [
        {
            id: 1,
            image: aboutUsImage1          
        },
        {
            id: 2,
            image: aboutUsImage2
        },
        {
            id: 3,
            image:aboutUsImage3
        },

        {
            id: 4,
            image:aboutUsImage4  
        },
        {
            id: 5,
            image: aboutUsImage5 
        },
        {
            id: 6,
            image: aboutUsImage6  
        },

    ];  

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '60px',
        autoplay: true,
        autoplaySpeed: 3000,
        lazyLoad: true,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    centerPadding: '40px',
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    centerPadding: '20px'
                },
            },
        ]
    };

    //console.log(breakpoints[2]['itemsToShow']);
    const renderCarouselItems = () => {
        return items.map((item, index) => (

            <div key={item.id}>
                <div className="elementor-widget-wrap elementor-element-populated aboutus">
                    <div className="elementor-element elementor-element-a3e395d elementor-widget elementor-widget-image" data-id="a3e395d" data-element_type="widget" data-widget_type="image.default">
                        <div className="elementor-widget-container carousel-item">
                          
                                <figure className="wp-caption">
                                    <div className='image-slide-aboutus'>
                                        <img className={`slider_img_border_aboutus`} src={item.image} alt={`Slide ${item.id}`} width="720px" height="720px" />
                                    </div>
                                  
                                </figure>
                            
                        </div>
                    </div>
                </div>
            </div>
        ));
    };


    return (
        <div className="container">
            <Slider {...settings} className="image-carousel-aboutus">
                {renderCarouselItems()}
            </Slider>
        </div>

    );
};

export default AboutUsCarousel;
