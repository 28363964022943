import React from "react";
import { Link } from "react-router-dom"
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import bellIcon from '../assets/images/landing-page/bell_icon.png'

// Convert UTC to readable time
const formatTime = (utcTime) => {
    const date = new Date(utcTime);

    const timeOptions = { hour: '2-digit', minute: '2-digit', hour12: true };
    const dateOptions = { day: '2-digit', month: 'short', year: 'numeric' };

    const formattedTime = date.toLocaleTimeString([], timeOptions).replace(':00', '');
    const formattedDate = date.toLocaleDateString('en-US', dateOptions).replace(' ', '');

    return `${formattedTime} ${formattedDate}`;
};

const Reminders = ({ remindersData }) => {

    //const { DTR } = remindersData.reminders || {};
    const settings = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        lazyLoad: true,
        // swipeToSlide: true,
        arrows: false,
        gap: 10,
        responsive: [
            {
                breakpoint: 1600,
                settings: {
                    slidesToShow: 5,
                    centerPadding: '40px',
                },
            },
            {
                breakpoint: 1440,
                settings: {
                    slidesToShow: 4,
                    centerPadding: '40px',
                },
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 4,
                    centerPadding: '40px',
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    centerPadding: '20px'
                },
            },
            {
                breakpoint: 380,
                settings: {
                    slidesToShow: 2,
                    centerPadding: '10px'
                },
            },
        ]
    };
    return (
        <div className="slider-container reminders-container similar-dots-container">
            {remindersData.DTR && remindersData.DTR.length > 0 &&
                <>
                    <h2>Reminders</h2>
                    <div className="reminder-list">
                        <Slider {...settings}>
                            {remindersData.DTR.map((reminder, index) => (
                                <Link target='_blank' to="https://learnee.page.link/rniX" key={index}>
                                    <div className="reminder-card" key={index}>
                                        <div className="reminder-title">{reminder.reminderTitle}</div>
                                        <div className="reminder-time">
                                           <img src={bellIcon} alt="bell" width={16} />
                                            {formatTime(reminder.triggerTimeInUTC)}
                                        </div>
                                    </div>
                                </Link>
                            ))}
                        </Slider>
                    </div>
                </>
            }
        </div>
    );
};

export default Reminders;
