import React, { useEffect, useState } from 'react'
import Axios from "axios"
import { Link, useLocation } from "react-router-dom" //useParams
import LoadingDotsIcon from "./LoadingDotsIcon"
import NotFound from "./NotFound"
import { formatWithMonthName, freeDots } from "./utils"
import defaultAvatar from "../assets/images/mystery-man.jpg"
//import HeaderLoggedOut from "./HeaderLoggedOut"
import FooterNew from "./FooterNew"
import PublicViewListing from "./PublicViewListing"
//import OverlayBottom from "./OverlayBottom"
import img_Apple_Store from "../assets/images/app-store-badge.svg"
import img_Play_Store from "../assets/images/google-play-badge.svg"
import NavBar from './NavBar'

const DetailPage = (props) => {
    //const { mktDotId } = useParams()
    const [isLoading, setIsLoading] = useState(true)
    const [post, setPost] = useState(null)
    const [moreDots, setMoreDots] = useState(null)
    const [isExpanded, setIsExpanded] = useState(false);


    const playStoreLink = "https://play.google.com/store/apps/details?id=com.learnee.vasi"
    const appStoreLink = "https://apps.apple.com/us/app/vasi-an-extension-of-your-mind/id1488476877"


    localStorage.removeItem("redirectionUrl")

    const [imageStatus, setIsLoadingImage] = useState(true)
    const handleImageLoaded = () => {
        setIsLoadingImage(false)
    }


    function useQuery() {
        return new URLSearchParams(useLocation().search);
    }

    const query = useQuery();
    const mktDotId = query.get('mktDotId');

    //console.log("mktDotId==", mktDotId);
    useEffect(() => {

        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Optional: for smooth scrolling
        });

        const URL = process.env.REACT_APP_BACKENDURL + 'fetchMarketPlaceDotForId';
        //const URL = process.env.REACT_APP_URL_API + 'fetchDotDetails';

        const ourRequest = Axios.CancelToken.source()
        const fetchPost = async () => {
            setIsLoading(true);
            try {
                const response = await Axios.post(URL, {
                    data: {
                        "mktPlace_id": mktDotId
                    }
                },
                    { cancelToken: ourRequest.token });
                const json = await response.data;
                setPost(json.result.marketPlaceDot);
                setMoreDots(json.result.dots)
                setIsLoading(false);
            } catch (e) {
                console.log("There was a problem or the request was cancelled.")
                setIsLoading(false);
            }
        }
        fetchPost();
        return () => {
            ourRequest.cancel()
        }
    }, [mktDotId])

    //console.log(post);

    if (!isLoading && !post) {
        return <NotFound />
    }

    // if (isLoading)
    //     return (
    //         <LoadingDotsIcon />
    //     )

    let profile_pic;
    let dateFormatted

    if (!isLoading) {
        profile_pic = ((post.payload.userAvatar === '' || post.payload.userAvatar === null) ? defaultAvatar : post.payload.userAvatar);
        const date = new Date(post.createdAt)
        dateFormatted = formatWithMonthName(date);
    }
    // Function to toggle the expanded state
    const toggleExpanded = () => {
        setIsExpanded(!isExpanded);
    };

    return (
        <div className="askvasi-home public-view-details">
            <NavBar />

            {isLoading ? (
                <div className="masthead_inner container outer-page detail_page">
                    <LoadingDotsIcon />
                </div>

            ) : (
                <>
                    <div className="masthead_inner container outer-page detail_page position-relative">

                        {post &&
                            <>
                                <div className="blog-header detail-page">
                                    <div className='clearfix'>
                                        <div className='title-width'>
                                            <strong className="blog-title detail-title">{post.dotTitle}</strong>
                                        </div>
                                        <div className="d-flex flex-wrap align-items-center">
                                            <small className="me-2">
                                                Views: {post.views}
                                            </small>
                                            <span className="me-2">|</span>
                                            <small className="me-2">
                                                {typeof post.price === 'undefined' ? 'Free' : freeDots(post.price)}
                                            </small>
                                            <span className="me-2">|</span>
                                            <span className="flex-column me-2">
                                                <small className="font-weight-light">{dateFormatted}</small>
                                            </span>
                                            <span className="me-2">|</span>
                                            <span className="d-flex align-items-center">
                                                <img className="avatar-tiny rounded-circle dots-avatar-bg me-2" alt="profile" src={profile_pic} />
                                                <small className="mb-0 font-weight-normal creator-color">{post.creator}</small>
                                            </span>
                                        </div>

                                    </div>
                                </div>

                                {/* <div className='clearfix margin-bottom-10'>
                            <div className='pull-left'>
                                {post.tags && post.tags.length > 0 && post.tags.map((tag, index) => {
                                    return (
                                        <span className="tagItem btn-margin" key={index}> {tag}</span>
                                    )
                                })
                                }
                            </div>

                        </div> */}
                            </>
                        }
                        {post &&
                            <div className="row margin-auto margin-top-details-top">
                                <div className="col-sm-12 blog-main">
                                    <div className="blog-post">
                                        <div className="para-perm desc" dangerouslySetInnerHTML={{ __html: post.payload.htmlDesc }} />
                                        {post && post.price > 0 && (
                                            <div className="box-shadow-bottom"></div>
                                        )}
                                    </div>
                                </div>
                            </div>

                        }
                        {post.payload.dotMedia && post.price === 0 && post.payload.dotMedia.length > 0 && !post.payload.dotMedia.every(item => item.type === 4) && (
                            <div className="row border-top margin-top-details">
                                <div className="card-group padding-left-0">
                                    <h5 className='attachment-title'>Attachment</h5>
                                </div>
                            </div>
                        )}
                        {post.payload.dotMedia && post.price === 0 && post.payload.dotMedia.length > 0 && (
                            <div className="row margin-auto">
                                {post.payload.dotMedia.map((media, i) => {

                                    if (media.type === 0 && media.url !== null) {
                                        return (
                                            <div className="col-md-4 mb-2" key={i}>
                                                <div className="thumbnail">
                                                    {imageStatus &&
                                                        <div className="filler-div"></div>
                                                    }
                                                    <img width="100%" onLoad={handleImageLoaded.bind(this)} src={media.url} alt="" className="image-clickable" />
                                                </div>
                                            </div>
                                        )
                                    } else if (media.type === 1 && media.url !== null) {
                                        return (
                                            <div className="col-md-4 mb-2" key={i}>
                                                <video controls width="100%" height="auto" poster={media.thumbUrl}>
                                                    <source src={media.url} type="video/mp4" />
                                                    Sorry, your browser doesn't support embedded videos.
                                                </video>

                                            </div>
                                        )
                                    } else if (media.type === 2 && media.url !== null) {
                                        return (
                                            <div className="col-md-4 mb-2" key={i}>
                                                <audio controls>
                                                    <source src={media.url} type="audio/ogg"></source>
                                                    <source src={media.url} type="audio/mpeg"></source>
                                                    {/*fallback for non supporting browsers goes here*/}
                                                    <p>Your browser does not support HTML5 audio, but you can still
                                                        <Link to={media.url}>download the music</Link>.</p>
                                                </audio>

                                            </div>
                                        )
                                    } else if (media.type === 3 && media.url !== null) {
                                        return (
                                            <div className="col-md-4 mb-2" key={i}>
                                                <iframe
                                                    src={media.url}
                                                    title="Vasi"
                                                    width="100%"
                                                    height="800"
                                                    loading="eager"
                                                    allowFullScreen={true}
                                                ></iframe>

                                            </div>
                                        )
                                    } else if (media.type === 4) {
                                        return (
                                            ''
                                        )
                                    } else {
                                        return (
                                            <div className="col-md-4 mb-2" key={i}>
                                                <div className="filler-div-audio filler-div-audio-details" style={{ backgroundColor: '#' + Math.random().toString(16).substr(-6) }}></div>
                                            </div>
                                        );
                                    }


                                })
                                }
                            </div>
                        )}
                        {post && post.price > 0 && (
                            <div className="row margin-auto priced-dot">
                                <div className='additional-info position-relative'>                                

                                {/* <div className="text-center overlay-header">To read the full contents, you need to purchase the Dot.</div> */}

                                <div className="text-center overlay-header">To read the full content, you need to purchase this Dot on VaSi App.</div>

                                    {/* <div className="header">
                                        Already Signed-up to VaSi <Link to="https://learnee.page.link/rniX">Login</Link>
                                    </div> */}
                                    {/* <div className="offer">
                                        Offer ends soon! Get 10% Off and get insightful election coverage with TOI+
                                    </div> */}
                                    <div className="benefits">
                                        <div className='overlay-header-bottom'>This Dot is exclusively available for App users only</div>
                                        <div className='overlay-ul'>Enhance productivity and reach your goals with these features:</div>
                                        <ul>
                                            <li>A variety of reminders to keep you focused on your goals.</li>
                                            <li>Turn anything and everything into Dots, elevate your impact and monetize your expertise.</li>
                                            <li>Explore a world of vibrant Dots created by our community on the marketplace.</li>
                                            <li>Get in-depth dots answering thousands of questions.</li>
                                            <li>Achieve your personal and professional goals and there is a lot waiting for you in the app.</li>
                                        </ul>
                                    </div>

                                    <div className="text-center overlay-bottom">We would love to have you onboard. Download now-</div>
                                </div>
                                <div className='storeImageDetailsInner'>
                                    <a target="_blank" rel="noreferrer" className="" href={`${appStoreLink}`}><img className="app-badge-apple-details" src={img_Apple_Store} alt="..." /></a>
                                    <a target="_blank" rel="noreferrer" href={`${playStoreLink}`} ><img className="app-badge-play-details" src={img_Play_Store} alt="..." /></a>
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="container detail_page_bottom">
                        {moreDots.length > 0 && (
                            <>
                                <h5 className='attachment-title'>Similar Dots</h5>
                                <PublicViewListing dotLists={moreDots} />
                            </>
                        )
                        }

                    </div>
                </>
            )}

            {post && post.price === 0 && (
                <div className='storeImageDetails'>

                    <h6 className={`bottom-text ${isExpanded ? 'expanded' : ''}`} onClick={toggleExpanded}>
                        Liked what you read? Find content that transforms your life! Download now
                    </h6>

                    <a target="_blank" rel="noreferrer" className="me-lg-3 mb-4 mb-lg-0" href={`${appStoreLink}`}><img className="app-badge-apple-details" src={img_Apple_Store} alt="..." /></a>
                    <a target="_blank" rel="noreferrer" href={`${playStoreLink}`} ><img className="app-badge-play-details" src={img_Play_Store} alt="..." /></a>
                </div>
            )}
            <FooterNew />
        </div>

    );
}

export default DetailPage;