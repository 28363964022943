import firebase from 'firebase/compat/app';
import { getDatabase } from 'firebase/database';
import 'firebase/compat/auth';
// Configure Firebase.
const config = {
  apiKey: process.env.REACT_APP_FIREBASEAPIKEY,
  authDomain: process.env.REACT_APP_FIREBASEAUTHDOMAIN,
  projectId: process.env.REACT_APP_FIREBASEPROJECTID,
  storageBucket: process.env.REACT_APP_FIREBASESTORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASEMESSAGINGSENDERID,
  appId: process.env.REACT_APP_FIREBASEAPPID,
  databaseURL: process.env.REACT_APP_REALTIMEDATABASE_URL
};
//firebase.initializeApp(config);

const firebaseApp = firebase.initializeApp(config);


// Initialize Realtime Database
const database = getDatabase(firebaseApp);


export {firebaseApp, database };
//export default firebase;