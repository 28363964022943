import React from "react";
//import { Link } from "react-router-dom"
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//import { PrevArrow, NextArrow } from './CustomArrows'; // Adjust the path as needed

const SuggestionCarousel = ({handleClick, selectedOption, suggestions}) => {
    //const DotsImagesMobileCarousel = () => {
   
    const settings = {
        dots: false,
        infinite: true, // Ensure this is set to false
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        lazyLoad: true,
        // prevArrow: <PrevArrow />,
        // nextArrow: <NextArrow />,
        arrows: false,
        autoplay: true,
        centerMode: false,
        gap: 10,
        adaptiveHeight: true // Optional: Adjust height based on current slide        
    };

    return (
        <div className=" slider-container ">
          
            <Slider {...settings}>
                {suggestions.map((content, index) => (
                      <button onClick={(event) => handleClick(event, content)} key={index} className={`suggestion-button ${selectedOption}`} > {content}</button>
                ))}
            </Slider>
        </div>
    );
};

export default SuggestionCarousel;