import React from "react"
import { Link } from "react-router-dom"
//import DashboardHeader from "./DashboardHeader"
//import DashboardFooter from "./DashboardFooter"

function NotFoundAdmin() {
  return (
    <div className="wrapper d-flex flex-column min-vh-100 bg-light">     
      <div className="text-center page-not-found">
        <h2>Whoops, we cannot find that page.</h2>
        <p className="lead text-muted">
          You can always visit the <Link to="/dashboard">homepage</Link> to get a fresh start.
        </p>
      </div>
      
    </div>
  )
}

export default NotFoundAdmin
