import React, {useState, useEffect, useContext, createContext} from 'react'
import { getAuth, onAuthStateChanged } from "firebase/auth";
//import 'firebase/compat/auth';

const AuthContext = createContext();

export const UseAuth = () =>{
    return useContext(AuthContext);
}

export const AuthProvider = ({children}) =>{
    const [user, setUser]  = useState(null)
    const [isAuthenticating, setIsAuthenticating] = useState(true)

    useEffect( () =>{
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      //if (user) { 
       setUser(user);
       setIsAuthenticating(false);  
     // } 
    });

    return () => unsubscribe();
}, []);

const values = {
    user,
    isAuthenticating
}

return (
<AuthContext.Provider value = {values} >
    { !isAuthenticating && children}
</AuthContext.Provider>
);

};