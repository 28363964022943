import React, { useState } from 'react';
import Axios from "axios";
import { Link, useLocation } from "react-router-dom"
import Tooltip from 'react-bootstrap/Tooltip';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { convertUTCDateToLocalDate, formatAMPM, getGatewayName } from "../utils"
import defaultAvatar from "../../assets/images/mystery-man.jpg"
import AdminToastAlert from "./AdminToastAlert"
import { getAuth } from "firebase/auth";

function UserPayoutList({ data }) {
    const [isButtonDisabled, setButtonDisabled] = useState({});
    const [showToast, setShowToast] = useState(false);
    const [content, setContent] = useState("");
    const [className, setClassName] = useState("");
    const [showToolTip, setShowToolTip] = useState({});
    const [payoutStatus, setPayoutStatus] = useState({});
    const [isNotifyUser, setIsNotifyUser] = useState(false);
    const [payoutUserData, setPayoutUserData] = useState(false);
    const [isNotifyButtonDisabled, setNotifyButtonDisabled] = useState(false);
    const [payoutFailureButton, setPayoutFailureButton] = useState({});

    const auth = getAuth();
    //console.log(auth.currentUser.uid);
    //const payout_URL = process.env.REACT_APP_BACKENDURL + 'stripeConnectPayout';
    const location = useLocation()
    //console.log(location);
    /*Submit report for MarketPlace id API*/
    const generatePayoutLink = async (data, id) => {
        //e.preventDefault();       
        const Success_URL = process.env.REACT_APP_HOMEURL + location.pathname.replace('/', '');
        const Cancel_URL = process.env.REACT_APP_HOMEURL + location.pathname.replace('/', '');
        //console.log(Success_URL);
        const ourRequest = Axios.CancelToken.source();
        setButtonDisabled({ [id]: true });
        //console.log(isButtonDisabled);
        //setButtonDisabled(true)
        //console.log("Data====", data);
        setPayoutUserData(data)
        console.log("payoutUserData===", payoutUserData)
        let payout_URL
        let requestData = {
            "amount": data.amount,
            "country_code": data.country,
            "user_id": data.userId,
            "success_URL": Success_URL,
            "cancel_URL": Cancel_URL,
            "transaction_id": data.transactionId,
            "loggedin_user_id": typeof auth.currentUser.uid !== 'undefined' ? auth.currentUser.uid : '0',
            "user_name": data.userName,
            "platform": data.platform
        }
        if (data.gatewayType === 2) {
            const shouldPay = window.confirm(`Are you sure you want to pay this user? The amount will be deducted from your PayPal wallet.`);
            if (!shouldPay) {
                setButtonDisabled({ [id]: false });
                return;
            }
            payout_URL = process.env.REACT_APP_BACKENDURL + 'paypalPayoutAdmin';
            requestData.recipient_email = data.email
        }else if (data.gatewayType === 3) {
            const shouldPay = window.confirm(`Are you sure you want to pay this user? The amount will be deducted from your VPA wallet.`);
            if (!shouldPay) {
                setButtonDisabled({ [id]: false });
                return;
            }
            payout_URL = process.env.REACT_APP_BACKENDURL + 'razorPayoutAdmin';
            requestData.fund_account_id = data.fundId
        }  else {
            payout_URL = process.env.REACT_APP_BACKENDURL + 'stripeConnectPayout';
            requestData.account_id = data.payoutAccountId
        }




        try {
            const response = await Axios.post(payout_URL, {
                data: requestData
            },
                { cancelToken: ourRequest.token }
            );
            const json = await response.data;
            //console.log(json.result.session_url)

            if (json.result.status === 201) {
                setButtonDisabled({ [id]: false });
                if (data.gatewayType === 2) {
                    setContent(json.result.message)
                    setShowToast(true)
                    setButtonDisabled({ [id]: false });
                } else {
                    window.location = json.result.session_url;
                }

            } else {
                if (json.result.status === 200 && (data.gatewayType === 2 || data.gatewayType === 3)) {
                    setClassName('success');
                    setContent(json.result.message)
                    setShowToast(true)
                    // Create a new array without the item at the specified index
                    //const updatedListData = payoutData.filter((_, index) => index !== id);

                    //setPayoutData(updatedListData);
                    setPayoutStatus({ [id]: true })
                    setButtonDisabled({ [id]: false });
                } else {
                    if (data.gatewayType === 2 || data.gatewayType === 3) {
                        setIsNotifyUser(true)
                        setPayoutStatus({ [id]: false })
                        setPayoutFailureButton({ [id]: true })
                    }
                    setClassName('danger');
                    setContent(json.result.message)
                    setShowToast(true)
                    setButtonDisabled({ [id]: false });
                }

            }
        } catch (er) {
            setClassName('danger');
            setContent("There was a problem or the request was cancelled.")
            setShowToast(true)
            setButtonDisabled({ [id]: false });
            //console.log("There was a problem or the request was cancelled." + er)
        }

        //console.log(showToast)

    }

    const handleClose = () => {
        setShowToast(false);
    }

    // const storeProfileData = (items) => {
    //    console.log(items);

    // }

    const copyClipboard = (id, data) => {
        // var date = new Date('August 21, 2000 18:02:25 GMT+05:00');
        //var date = new Date('Thu Dec 15 2022 10:01:43 GMT+05:30');
        // console.log(date.toLocaleString());

        navigator.clipboard.writeText(data);
        setShowToolTip({ [id]: true });
    }

    const notifyUser = async () => {
        // Handle the OK button click action
        console.log("data====", data);
        //notifyPayoutUsers
        setNotifyButtonDisabled(true)
        const notifyPayoutUsersURL = process.env.REACT_APP_BACKENDURL + 'notifyPayoutUsers';
        Axios.defaults.headers = {
            Authorization: 'Bearer ' + localStorage.getItem('userAuth')
        }
        const filterRequest = Axios.CancelToken.source();

        try {
            const response = await Axios.post(notifyPayoutUsersURL, {
                data: {
                    "platform": payoutUserData.platform,
                    "topic": payoutUserData.firebaseUID,
                    "email": payoutUserData.email,
                    "userName": payoutUserData.userName,
                    "user_id": payoutUserData.userId
                }
            },
                { cancelToken: filterRequest.token });
            const json = await response.data.result;
            if (json.status === 200) {
                setClassName('success');
                setContent("Email and push notification sent successfully!")
                setIsNotifyUser(false)
            } else {
                setContent("There was a problem or the request was cancelled.")
            }
            //setIsLoading(false);
            //console.log(payoutListReferral);
        } catch (er) {
            if (er.response.status === 401) {
                localStorage.clear();
                window.location.href = process.env.REACT_APP_HOMEURL + 'login'
            }
            //setCheckingInProgress(false)
            console.log("There was a problem or the request was cancelled.")
        }
        setNotifyButtonDisabled(false)
        //setShowToast(false);

    };

    return (

        <table className="table border mb-0">
            <thead className="table-light fw-semibold">
                <tr className="align-middle">
                    <th className="text-center">
                        <i className="icon fa fa-users" aria-hidden="true"></i>

                    </th>
                    <th>User</th>
                    <th className="text-center">Email</th>
                    <th className="text-center">Country</th>
                    <th className="text-center">Payment Gateway</th>
                    <th className="text-center">Account Id</th>
                    <th className="text-center">Amount</th>
                    <th className="text-center">UserId</th>
                    <th></th>
                </tr>
            </thead>
            <tbody>

                {data.map((item, index) => (
                    <tr className="align-middle" key={Math.random()}>
                        <td className="text-center">
                            <div className="avatar avatar-md">
                                <Link state={item} className="nav-link" to={`/dashboard/profile/${item.userId}`}>
                                    <img className="avatar-img-40" src={typeof item.avatar === 'undefined' || item.avatar === '' || item.avatar === null ? defaultAvatar : item.avatar} alt="avatar" />
                                </Link>
                                {/* <span className="avatar-status bg-success"></span> */}
                            </div>
                        </td>
                        <td>
                            <div> <Link state={item} className="nav-link" to={`/dashboard/profile/${item.userId}`}>{typeof item.userName === 'undefined' || item.userName === null ? 'Anonmous' : item.userName}</Link></div>
                            {item.status === 51 &&
                                <div className="small text-medium-emphasis">Requested On: {formatAMPM(convertUTCDateToLocalDate(new Date(item.createdAt)))}</div>
                            }
                            {item.status === 52 &&
                                <div className="small text-medium-emphasis">Paid On: {formatAMPM(convertUTCDateToLocalDate(new Date(item.updatedAt)))}</div>
                            }

                            {item.status === 55 &&
                                <div className="small text-medium-emphasis">Payout Completed On: {formatAMPM(convertUTCDateToLocalDate(new Date(item.updatedAt)))}</div>
                            }

                            {/* {formatAMPM(new Date(item.createdAt))} */}
                        </td>
                        <td className="text-center">
                            {typeof item.email === 'undefined' || item.email === null ? 'N/A' : item.email}

                        </td>
                        <td className="text-center">
                            {item.country}

                        </td>

                        <td>
                            <div className="clearfix">
                                <div className="float-start">
                                    {typeof item.gatewayType !== 'undefined' && getGatewayName(item.gatewayType)}
                                    {/* {typeof item.gatewayType !== 'undefined' && item.gatewayType === 2 ? 'Paypal' : 'Stripe'} */}
                                </div>
                            </div>

                        </td>

                        <td>
                            <div className="clearfix">
                                <div className="float-start">
                                    {item.payoutAccountId === '' ? 'N/A' : item.payoutAccountId}
                                </div>
                            </div>

                        </td>
                        <td>
                            <div className="clearfix">
                                <div className="float-start">
                                    <strong>{item.country === 'IN' ? '₹' : '$'}{item.amount}</strong>
                                </div>
                            </div>

                        </td>
                        <td className="text-center">
                            {item.userId.slice(0, 4)}...{item.userId.slice(-4)} &nbsp;
                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{showToolTip[index] && <span>Copied to clipboard!</span>}{!showToolTip[index] && <span>Copy</span>}</Tooltip>}>
                                <span className="d-inline-block">
                                    <i style={{ cursor: "pointer" }} onClick={() => copyClipboard(index, item.userId)} className={"fa fa-files-o " + (showToolTip[index] ? 'file-icon-color' : '')} aria-hidden="true"></i>
                                </span>
                            </OverlayTrigger>
                        </td>
                        {/*onClick={storeProfileData.bind(item)} */}

                        <td>
                            <div className="dropdown">
                                {(item.status === 51 && !payoutStatus[index]) &&
                                    <button onClick={() => generatePayoutLink(item, index)} className={"btn p-2 btn-primary " + (isButtonDisabled[index] ? 'loader-btn' : '')} type="button" data-coreui-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                        {payoutFailureButton[index] ? 'Failed' : 'Pay'}
                                    </button>
                                }
                                {/* {(item.status === 55 && !payoutStatus[index]) && */}
                                {(item.status === 55 || payoutStatus[index]) &&
                                    <strong style={{ color: '#008000' }}>Completed</strong>
                                }

                                {item.status === 52 &&
                                    <strong style={{ color: '#008000' }}>In Transit</strong>
                                }
                                {/* <Link state={item} className="nav-link" to={`/dashboard/profile/${item.userId}`}>View Profile</Link> */}
                            </div>
                        </td>
                    </tr>
                ))}
            </tbody>

            {showToast &&
                <AdminToastAlert toastClose={handleClose} isNotifyUser={isNotifyUser} notifyUser={notifyUser} toastShow={showToast} content={content} isNotifyButtonDisabled={isNotifyButtonDisabled} className={className}></AdminToastAlert>
            }

        </table>




    );
}

export default UserPayoutList;