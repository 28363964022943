import React from "react"
import GooglePlayImg from '../assets/images/landing-page/GooglePlay.png';
import AppStoreImg from '../assets/images/landing-page/AppStore.png';
import Li_icon from '../assets/images/landing-page/li.png';
import Group_icon from '../assets/images/landing-page/Group.png';
import twitter_icon from '../assets/images/landing-page/twitter.png';


function LandingPageFooter() {
    return (
        <div className="footer-container" id="footerContainer2">
            <div className="footer_bg_left"></div>
            <div className="footer_bg_right"></div>
            <div className="left-section">
                <div className="footer-heading">
                Unlock your knowledge’s value
                    {/* <span className="outlined-text">Connect&nbsp;</span>
                    Dots&nbsp;
                    <span className="outlined-text">for&nbsp;</span>
                    Meaningful&nbsp;
                    <span className="outlined-text">Life&nbsp;</span> */}
                </div>

                <p className="footer-description">Let’s take back the content creation space <br/> and make it about value again</p>

                <div className="app-buttons">
                    <a target="_blank" rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.learnee.vasi"><img src={GooglePlayImg} alt="Google Play" /></a>
                    <a href="https://apps.apple.com/us/app/vasi-extend-your-mind/id1488476877" target="_blank" rel="noreferrer"><img src={AppStoreImg} alt="App Store" /></a>
                </div>
            </div>

            <div className="bottom-section">
                <div className="footer-row">
                    <div className="footer-links">
                        <a rel="noreferrer" href="/faq/">FAQ</a>
                        <a rel="noreferrer"href="/privacy-policy-usa/">Privacy Policy</a>
                        <a rel="noreferrer" href="/terms-of-service-usa/">Terms & Conditions</a>
                    </div>

                    <div className="footer-social-icons">
                        <a target="_blank" rel="noreferrer" href="https://www.linkedin.com/in/vasi-ai-301267272/"><img style={{ width: '50px', }} src={Li_icon} alt="LinkedIn" /></a>
                        <a target="_blank" rel="noreferrer" href="https://www.instagram.com/vasi.ai/"><img style={{ width: '50px' }} src={Group_icon} alt="Instagram" /></a>
                        <a target="_blank" rel="noreferrer" href="https://twitter.com/VaSi_Ai/"><img style={{ width: '50px' }} src={twitter_icon} alt="X" /></a>
                    </div>
                </div>

                <p className="footer-bottom-text">© {new Date().getFullYear()} All rights reserved by VaSi</p>
            </div>
        </div>

    );
};

export default LandingPageFooter;