import React, { useState, useEffect } from 'react';
import Axios from "axios"
//import { useParams } from "react-router-dom"
import { DropdownButton, Dropdown } from "react-bootstrap";
import InfiniteScroll from 'react-infinite-scroll-component';
import DashboardHeader from "./DashboardHeader"
import DashboardFooter from "./DashboardFooter"
import UserReferralPayoutList from "./UserReferralPayoutList"
//import ApiFetchingData from '../ApiFetchingData';
import LoadingDotsIcon from "../LoadingDotsIcon"



function ReferralPayoutUserLists() {
    //const { pagenum } = useParams();
    const [search_query, setSearchQuery] = useState('');
    //const [currentPage, setCurrentPage] = useState(1);
    // const [recordsPerPage] = useState(6);
    const [selected, setSelected] = useState('Payout Pending');
    const [payoutListReferral, setPayoutListReferral] = useState();
    const [payoutStatus, setPayoutStatus] = useState(51)
    const [isLoading, setIsLoading] = useState(true)
    const pageSize = process.env.REACT_APP_PAGESIZE
    const [pageOffset, setPageOffset] = useState(pageSize);
    const [noMore, setnoMore] = useState(true)
    const [, setIsFirstLoad] = useState(true)
    const payoutRequestUrl = process.env.REACT_APP_BACKENDURL + 'getPayoutReferralRequests';
    const payoutRequestUrlSearch = process.env.REACT_APP_BACKENDURL + 'searchPendingPayouts';


    const list = [
        { key: "51", value: "Payout Pending" },
        { key: "52", value: "Paid by Learnee" },
        { key: "55", value: "Payout Completed" }

    ];

    useEffect(() => {
        const ourRequest = Axios.CancelToken.source()
        const payoutRequestUrl = process.env.REACT_APP_BACKENDURL + 'getPayoutReferralRequests';
        const payoutReadyLists = async (payoutStatus) => {
            //setCheckingInProgress(true)    
            setIsLoading(true);
            Axios.defaults.headers = {
                Authorization: 'Bearer ' + localStorage.getItem('userAuth')
            }

            try {
                const response = await Axios.post(payoutRequestUrl, {
                    data: {
                        "pageOffset": 0,
                        "pageSize": pageSize,
                        "status": payoutStatus,
                        "payout_type": "referral"
                    }
                },
                    { cancelToken: ourRequest.token });
                const json = await response.data.result;
                if (json.status === 200) {
                    setPayoutListReferral(json.payouts);
                }
                setIsLoading(false);
                //console.log(payoutListReferral);
            } catch (er) {
                if (er.response.status === 401) {
                    localStorage.clear();
                    window.location.href = process.env.REACT_APP_HOMEURL + 'login'
                }
                //setCheckingInProgress(false)
                console.log("There was a problem or the request was cancelled.")
                setIsLoading(false);
            }

        }

        payoutReadyLists(payoutStatus);
        localStorage.setItem('selectedFilter', payoutStatus);
    }, [pageSize, payoutStatus])

    //const indexOfLastRecord = currentPage * recordsPerPage;
    //const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
    //const currentRecords = data.slice(indexOfFirstRecord, indexOfLastRecord);
    //const nPages = Math.ceil(20 / recordsPerPage)

    function handleSelect(key, value) {
        setPayoutStatus(parseInt(key));
        fetchFilteredData(parseInt(key));
        setSearchQuery('')
        setSelected(value);
        localStorage.setItem('selectedFilter', parseInt(key));
    };

    const fetchFilteredData = async (payoutStatus) => {
        //setCheckingInProgress(true)  
        setPageOffset(pageSize);
        setIsLoading(true);
        Axios.defaults.headers = {
            Authorization: 'Bearer ' + localStorage.getItem('userAuth')
        }
        const filterRequest = Axios.CancelToken.source();
        setIsFirstLoad(false)

        try {
            const response = await Axios.post(payoutRequestUrl, {
                data: {
                    "pageOffset": 0,
                    "pageSize": pageSize,
                    "status": payoutStatus,
                    "payout_type": "referral"
                }
            },
                { cancelToken: filterRequest.token });
            const json = await response.data.result;
            if (json.status === 200) {
                setPayoutListReferral(json.payouts);
            }

            if (json.status === 400) {
                setPayoutListReferral('');
            }
            setIsLoading(false);
            //console.log(payoutListReferral);
        } catch (er) {
            if (er.response.status === 401) {
                localStorage.clear();
                window.location.href = process.env.REACT_APP_HOMEURL + 'login'
            }
            //setCheckingInProgress(false)
            console.log("There was a problem or the request was cancelled.")
        }

    }

    const fetchMoreReferralData = async (payoutStatus) => {
        const ourRequest = Axios.CancelToken.source();
        Axios.defaults.headers = {
            Authorization: 'Bearer ' + localStorage.getItem('userAuth')
        }
        setIsFirstLoad(false)
        var requestURL = payoutRequestUrl;

        var selectedFilter = localStorage.getItem('selectedFilter') !== null ? parseInt(localStorage.getItem('selectedFilter')) : 51

        var requestData = {
            "pageOffset": pageOffset,
            "pageSize": pageSize,
            "status": typeof payoutStatus === 'undefined' ? selectedFilter : payoutStatus,
            "payout_type": "referral"
        }

        if (search_query !== '') {
            requestURL = payoutRequestUrlSearch;//Request URL changed in case of search
            requestData = {
                "pageOffset": pageOffset,
                "pageSize": pageSize,
                "status": typeof payoutStatus === 'undefined' ? selectedFilter : payoutStatus,
                "search_value": search_query,
                "search_by": "userId",
                "payout_type": "referral"
            }
        }

        console.log(requestData);

        try {
            const response = await Axios.post(requestURL, {
                data: requestData,
            },
                { cancelToken: ourRequest.token }
            );
            const json = await response.data.result;
            setIsFirstLoad(true)
            return json.payouts;
        } catch (e) {
            setIsFirstLoad(true)
            console.log("There was a problem or the request was cancelled." + e)
            //setIsLoading(false);
        }
    }

    const fetchData = async () => {
        console.log('payoutListReferral:', payoutListReferral);
        const payoutReferralListsLoadMore = await fetchMoreReferralData();
        //console.log(payoutReferralListsLoadMore);
        if (payoutReferralListsLoadMore) {
            //console.log('Previous state:', payoutListReferral);
            //console.log('Load more data:', payoutReferralListsLoadMore);


            setPayoutListReferral((payoutListReferral) => [...payoutListReferral, ...payoutReferralListsLoadMore]);

            console.log('Updated state:', payoutListReferral);

            // setPayoutListReferral((prevPayoutListReferral) => [...prevPayoutListReferral, ...payoutReferralListsLoadMore]);
            if (payoutReferralListsLoadMore.length === 0 || payoutReferralListsLoadMore.length < Number(pageSize)) {
                setnoMore(false);
            }
            setPageOffset(payoutListReferral.length + Number(pageSize))
        }
    }


    const fetchSearchedData = async (payoutStatus) => {
        //setCheckingInProgress(true)    
        setIsLoading(true);
        Axios.defaults.headers = {
            Authorization: 'Bearer ' + localStorage.getItem('userAuth')
        }
        const filterRequest = Axios.CancelToken.source();
        setIsFirstLoad(false)
        try {
            const response = await Axios.post(payoutRequestUrlSearch, {
                data: {
                    "pageOffset": 0,
                    "pageSize": pageSize,
                    "status": payoutStatus,
                    "search_value": search_query,
                    "payout_type": "referral",
                    "search_by": "userId"
                }
            },
                { cancelToken: filterRequest.token });
            const json = await response.data.result;
            if (json.status === 200) {
                setPayoutListReferral(json.payouts);
            }

            if (json.status === 400) {
                setPayoutListReferral('');
            }
            setIsLoading(false);
            //console.log(payoutListReferral);
        } catch (er) {
            if (er.response.status === 401) {
                localStorage.clear();
                window.location.href = process.env.REACT_APP_HOMEURL + 'login'
            }
            //setCheckingInProgress(false)
            console.log("There was a problem or the request was cancelled.")
        }

    }

    function handleSearchInput(e) {
        e.preventDefault();
        const value = e.target.value
        //console.log(value);
        setSearchQuery(value)
    }

    function submitSearchData(e) {
        e.preventDefault();
        if (search_query !== '') {
            fetchSearchedData(payoutStatus);
        } else {
            fetchFilteredData(payoutStatus);
            setnoMore(true);
        }
        //console.log(search_q);
    }

    return (
        <div className="wrapper d-flex flex-column min-vh-100 bg-light userlist">
            <DashboardHeader></DashboardHeader>
            <div className='container-fluid'>
                <div className="dropdown header-nav d-md-flex" style={{ float: "left" }}>
                    <DropdownButton
                        id="dropdown-basic-button"
                        variant="info"
                        className="floatRight"
                        title={selected}
                    >
                        {list.map((item, index) => {
                            return (
                                <Dropdown.Item onClick={() => handleSelect(item.key, item.value)} key={index} eventKey={item.value}>
                                    {item.value}
                                </Dropdown.Item>
                            );
                        })}
                    </DropdownButton>

                </div>
                <div className='ms-3 float-right'>
                    <form className='me-xxl-3 admin-search'>
                        <div className="input-group">
                            <input className="form-control py-2 border-right-0 border searchBox" placeholder="Search users via userId..." onChange={handleSearchInput} type="text" id="srch-term" value={search_query} />
                        </div>
                        <span className="margin-left-10">
                            <button className="btn btn-primary border-left-0" type="submit" onClick={submitSearchData}>
                                <i className="fa fa-search"></i>
                            </button>
                        </span>
                    </form>
                </div>
            </div>
            <div className="card-body">
                <div className="table-responsive">

                    {isLoading &&
                        <LoadingDotsIcon />
                    }



                    {typeof payoutListReferral !== 'undefined' &&
                        <InfiniteScroll
                            dataLength={payoutListReferral.length}
                            next={fetchData}
                            hasMore={noMore}
                            // loader={payoutListReferral.length >= 20 && <ApiFetchingData />}
                        >

                            {payoutListReferral &&
                                <UserReferralPayoutList
                                    data={payoutListReferral} />
                            }

                            {!payoutListReferral && !isLoading &&
                                <div className="text-center margin-top-10">No Result!</div>
                            }

                        </InfiniteScroll>
                    }

                </div>
            </div>
            <DashboardFooter></DashboardFooter>
        </div>
    );
}

export default ReferralPayoutUserLists;