import React, { useState, useEffect, useRef } from 'react';
import { database } from '../Firebase'; // Your Firebase config
import { ref, onValue } from 'firebase/database'; // Import modular database methods
import Axios from 'axios';
import { v4 as uuidv4 } from 'uuid';
import DOMPurify from 'dompurify';

import { publicIpv4 } from 'public-ip';
import NavBar from './NavBar'


import SimilarDots from './SimilarDots';
import Reminders from "./Reminders";
import '../assets/css/home_new.css';
import '../assets/css/home.css';
import '../assets/css/animation.css'
import '../assets/css/landingpage.css';
import LandingPageFooter from './LandingPageFooter';
import DotsImagesMobileCarousel from './BlurredImages';
import SuggestionCarousel from './SuggestionCarousel';

import thumbnail_page_1_img_1 from '../assets/images/landing-page/page-1-thumbnail-1.png';
import thumbnail_page_1_img_2 from '../assets/images/landing-page/page-1-thumbnail-2.png';
import thumbnail_page_1_img_3 from '../assets/images/landing-page/page-1-thumbnail-3.png';
import thumbnail_page_1_img_4 from '../assets/images/landing-page/page-1-thumbnail-4.png';
import thumbnail_page_1_img_5 from '../assets/images/landing-page/page-1-thumbnail-5.png';
import thumbnail_page_1_img_6 from '../assets/images/landing-page/page-1-thumbnail-6.png';

import thumbnail_page_2_img_1 from '../assets/images/landing-page/page-2-thumbnail-1.png';
import thumbnail_page_2_img_2 from '../assets/images/landing-page/page-2-thumbnail-2.png';
import thumbnail_page_2_img_3 from '../assets/images/landing-page/page-2-thumbnail-3.png';
import thumbnail_page_2_img_4 from '../assets/images/landing-page/page-2-thumbnail-4.png';
import thumbnail_page_2_img_5 from '../assets/images/landing-page/page-2-thumbnail-5.png';

import thumbnail_page_3_img_1 from '../assets/images/landing-page/page-3-thumbnail-1.png';
import thumbnail_page_3_img_2 from '../assets/images/landing-page/page-3-thumbnail-2.png';
import thumbnail_page_3_img_3 from '../assets/images/landing-page/page-3-thumbnail-3.png';
import thumbnail_page_3_img_4 from '../assets/images/landing-page/page-3-thumbnail-4.png';
import thumbnail_page_3_img_5 from '../assets/images/landing-page/page-3-thumbnail-5.png';

import thumbnail_page_5_img_1 from '../assets/images/landing-page/1st.png';
import thumbnail_page_5_img_2 from '../assets/images/landing-page/2nd.png';
import thumbnail_page_5_img_3 from '../assets/images/landing-page/3rd.png';
import thumbnail_page_5_img_4 from '../assets/images/landing-page/4th.png';
import thumbnail_page_5_img_5 from '../assets/images/landing-page/5th.png';
import thumbnail_page_5_img_6 from '../assets/images/landing-page/6th.png';

// import send_btn from '../assets/images/landing-page/send-2.png';

// import send_btn_new from '../assets/images/landing-page/send-btn.png';

import vasi_logo_circle from '../assets/images/landing-page/vasi_logo_circle.png';

import box1_img_3 from '../assets/images/landing-page/box1-img-3.png';
import box1_img_2 from '../assets/images/landing-page/box1-img-2.png';
import box1_img_1 from '../assets/images/landing-page/box1-img-1.png';

import box2_img_3 from '../assets/images/landing-page/box2-img-3.png';
import box2_img_2 from '../assets/images/landing-page/box2-img-2.png';
import box2_img_1 from '../assets/images/landing-page/box2-img-1.png';

import box3_img_3 from '../assets/images/landing-page/box3-img-3.png';
import box3_img_2 from '../assets/images/landing-page/box3-img-2.png';
import box3_img_1 from '../assets/images/landing-page/box3-img-1.png';


import personal from '../assets/images/landing-page/personal.png';
import explore from '../assets/images/landing-page/explore.png';
import marketplace from '../assets/images/landing-page/marketplace.png';

import GooglePlayImg from '../assets/images/landing-page/GooglePlay.png';
import AppStoreImg from '../assets/images/landing-page/AppStore.png';

import DownButton from '../assets/images/landing-page/Downbutton.png';
import saveIcon from '../assets/images/landing-page/save_icon.png';

import logo_vasi_animated from '../assets/images/landing-page/vasi-animated-logo-2.gif';
import TextSlider from './TextSlider';

// App Component
function App() {

    const [currentPage, setCurrentPage] = useState(0);
    const [, setTypewriterIndex] = useState(0); //typewriterIndex
    const [isNextButtonVisible, setNextButtonVisible] = useState(false);
    const [typewriterText, setTypewriterText] = useState("");

    const [isFooterVisible, setFooterVisible] = useState(false);

    const [userInput, setUserInput] = useState(''); // Store user input
    const [responseReceived, setResponseReceived] = useState(false);
    const [conversation, setConversation] = useState([]); // Array to store conversation
    const [fullConversation, setFullConversation] = useState([]); // Array to store conversation
    const [loadingResponse, setLoadingResponse] = useState(false); // To show a loading indicator while waiting for response

    const generateAskVaSiDotsWebUrl = process.env.REACT_APP_BACKENDURL + 'askVaSiPublicApi';

    const [isAnimated, setIsAnimated] = useState(false);
    const [isConvesation, setIsConvesation] = useState(false);
    const [showArrow, setShowArrow] = useState(false); // To show/hide the scroll arrow

    const bottomRef = useRef(null); // Ref for scrolling to the bottom
    const menuRef = useRef(null);

    const [showOptions, setShowOptions] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const [isActive, setIsActive] = useState(false);
    const [isLoadingInitial, setIsLoadingInitial] = useState(false);
    const [gifSrc, setGifSrc] = useState();
    const gifLoaded = useRef(false); // Track if the GIF has been loaded once

    const [isConversation, setIsConversation] = useState(false);
    const [currentSuggestionIndex, setCurrentSuggestionIndex] = useState(0);
    const [isTransitioning, setIsTransitioning] = useState(false);
    const [isHovered, setIsHovered] = useState(false);
    const [isBlinking, setBlinking] = useState(false);
    const [showCircle, setShowCircle] = useState(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const [isBottomOpen, setIsBottomOpen] = useState(false);

    const [suggestions, setSuggestions] = useState([
        'Prepare my timetable 📅',
        'Fix my sleep schedule 🛌',
        'Help me prepare for my presentation 🎤',
        // 'Research for me 🔍',
        // 'Make a meditation routine 🧘‍♀️',
    ]);

    const text = "Start learning, exploring and earning";

    //console.log(process.env.REACT_APP_REALTIMEDATABASE_URL);

    // State to track the current index of the word

    const [isLoadedGif, setIsLoadedGif] = useState(false);
    const contentEditableRef = useRef(null);

    const icons = {
        personal: personal, // personal icon
        explore: explore, //  explore icon
        publish: marketplace, //  publish icon
    };


    const handleClickOutside = (event) => {
        if (menuRef.current && !menuRef.current.contains(event.target)) {
            setShowOptions(false);
        }
    };


    useEffect(() => {
        if (showOptions) {
            document.addEventListener('mousedown', handleClickOutside);
        } else {
            document.removeEventListener('mousedown', handleClickOutside);
        }
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showOptions]);



    useEffect(() => {
        const interval = setInterval(() => {
            if (!isHovered) {
                setIsTransitioning(true);
                setTimeout(() => {
                    setCurrentSuggestionIndex(prevIndex => (prevIndex + 1) % suggestions.length);
                    setIsTransitioning(false);
                }, 1000); // Duration of the animation
            }
        }, 4000); // Change suggestions every 3 seconds

        return () => clearInterval(interval); // Cleanup on component unmount
    }, [isHovered]);

    const displayedSuggestions = suggestions.slice(currentSuggestionIndex, currentSuggestionIndex + 3).concat(
        suggestions.slice(0, Math.max(0, currentSuggestionIndex + 3 - suggestions.length))
    );


    useEffect(() => {
        if (!gifLoaded.current) {
            const uniqueSrc = `${logo_vasi_animated}?t=${new Date().getTime()}`; // Append timestamp to avoid caching
            setGifSrc(uniqueSrc);
            gifLoaded.current = true; // Set it to true after loading
        }
    }, []);

    // Handle image load event
    const handleImageLoad = () => {
        setIsLoadedGif(true);  // Once the GIF is fully loaded, mark it as loaded
    };


    // Array of words to replace "iPhone"
    const words = ['iPhone', 'Trip', 'Outfit', 'Gadget', 'Kicks'];
    const interval = 3000;
    const duration = 2000;

    // Array of content values
    const suggestions_p = [
        'Prepare my timetable 📅',
        'Fix my sleep schedule 🛌',
        'Research for me 🔍',
        'Meditation Routine 🧘‍♀️'
        //'Make a meditation routine 🧘‍♀️',
    ];

    const suggestions_m = [
        'Top 10 Books for Entrepreneurs 📚',
        // 'My top 10 Book recommendations for entrepreneurs 📚',
        'A mistake that changed my life 🔄',
        'Share personal growth journey to inspire 🌱',
        '10 Secrets to a Happy Marriage 💑'
        //'10 tips from my long years of a happy marriage 💑'
    ];

    const suggestions_e = [
        'Productivity hacks for freshers 🚀',
        'DIY Date Night ideas 💡❤️',
        'Life lessons from solo traveller 🌍',
        // 'Meditation techniques for letting go 🧘‍♂️',
        'Easy ways to get in shape 💪'
    ];

    // Function to generate a UUID
    function generateUUID() {
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
            const r = Math.random() * 16 | 0, v = c == 'x' ? r : (r & 0x3 | 0x8);
            return v.toString(16);
        });
    }

    // Function to set a cookie
    function setCookie(name, value, days) {
        let expires = "";
        if (days) {
            const date = new Date();
            date.setFullYear(date.getFullYear() + 100);
            //date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            expires = "; expires=" + date.toUTCString();
        }
        document.cookie = name + "=" + (value || "") + expires + "; path=/";
    }

    // Function to get a cookie by name
    function getCookie(name) {
        const nameEQ = name + "=";
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }


    // const streamingData = async (key, uniqueKey) => {

    //     //console.log(database)
    //     const dataRef = ref(database, `web_prompts/${uniqueKey}/${key}`);
    //     setIsConvesation(true);

    //     // Set up the listener for Firebase Realtime Database
    //     const unsubscribe = onValue(dataRef, (snapshot) => {
    //         if (snapshot.exists()) {
    //             const data = snapshot.val();
    //             //console.log("Data received: ", fetchedData);
    //             const botMessage = data.description;

    //             if (botMessage) { // Always replace the last bot message or add it if none exists
    //                 setIsLoadingInitial(false)
    //                 setConversation((prevConversation) => {
    //                     const updatedConversation = [...prevConversation];

    //                     // Find the last user input
    //                     const lastUserMessageIndex = updatedConversation
    //                         .slice()
    //                         .reverse()
    //                         .findIndex((entry) => entry.role === 'user');

    //                     // Find the last bot response after the user input
    //                     const lastBotMessageIndex = updatedConversation
    //                         .slice(lastUserMessageIndex)
    //                         .findIndex((entry) => entry.role === 'system');

    //                     // Check if we already have a bot response for the current user input                       
    //                     if (lastBotMessageIndex !== -1 && typeof updatedConversation[lastUserMessageIndex + lastBotMessageIndex] !== 'undefined') {
    //                         // Update the last bot message for the ongoing conversation
    //                         updatedConversation[lastUserMessageIndex + lastBotMessageIndex].content = botMessage;
    //                     } else {
    //                         // If no bot response exists yet, add the new bot response after the user content
    //                         updatedConversation.push({
    //                             role: 'system',
    //                             content: botMessage,
    //                         });
    //                     }

    //                     return updatedConversation;
    //                 });
    //             }

    //         } else {
    //             console.log("No data available");

    //         }
    //     }, (error) => {
    //         console.error("Error fetching data:", error);
    //     });

    //     // Cleanup listener when the component is unmounted
    //     return () => {
    //         console.error("unsubscribe...");
    //         unsubscribe();
    //     };
    // }


    const streamingData = async (key, uniqueKey) => {
        const dataRef = ref(database, `web_prompts/${uniqueKey}/${key}`);
        setIsConvesation(true);
    
        // Track the last processed bot message
        let lastProcessedBotMessage = null;
    
        // Set up the listener for Firebase Realtime Database
        const unsubscribe = onValue(dataRef, (snapshot) => {
            if (snapshot.exists()) {
                const data = snapshot.val();
                const botMessage = data.description;
    
                if (botMessage && botMessage !== lastProcessedBotMessage) {
                    // Update last processed message
                    lastProcessedBotMessage = botMessage;
    
                    setIsLoadingInitial(false);
                    setConversation((prevConversation) => {
                        const updatedConversation = [...prevConversation];
    
                        // Find the last user message
                        const lastUserMessageIndex = updatedConversation
                            .slice()
                            .reverse()
                            .findIndex((entry) => entry.role === 'user');
    
                        const absoluteUserIndex = lastUserMessageIndex !== -1 
                            ? updatedConversation.length - 1 - lastUserMessageIndex 
                            : -1;
    
                        if (absoluteUserIndex !== -1) {
                            // Find the last bot response after the user input
                            const lastBotMessageIndex = updatedConversation
                                .slice(absoluteUserIndex + 1)
                                .findIndex((entry) => entry.role === 'system');
    
                            if (lastBotMessageIndex !== -1) {
                                // Update the existing bot message
                                updatedConversation[absoluteUserIndex + 1 + lastBotMessageIndex].content = botMessage;
                            } else {
                                // Add a new bot message if none exists
                                updatedConversation.push({
                                    role: 'system',
                                    content: botMessage,
                                });
                            }
                        } else {
                            // If no user message is found, add bot message to the end
                            updatedConversation.push({
                                role: 'system',
                                content: botMessage,
                            });
                        }
    
                        return updatedConversation;
                    });
                }
            } else {
                console.log("No data available");
            }
        }, (error) => {
            console.error("Error fetching data:", error);
        });
    
        // Cleanup listener when the component is unmounted
        return () => {
            console.log("unsubscribe...");
            unsubscribe();
        };
    };
    



    // Function to handle sending the first content through Firebase Functions
    const sendInitialMessage = async (content) => {
        //if (!userInput.trim()) return; // Prevent sending empty messages

        if (userInput === '' && content === '') return; // Prevent sending empty messages

        const inputQuery = (userInput === '' ? content : userInput)

        if (!inputQuery.trim()) return; // Prevent sending empty messages

        //console.log(await publicIpv4());

        //const userAgent = navigator.userAgent;
        setShowOptions(false);


        document.body.classList.remove('no-scroll');

        const key = uuidv4();


        // Check if UUID cookie exists, if not, generate and store a new one
        let userUUID = getCookie('userUUID');
        if (!userUUID) {
            userUUID = generateUUID();
            setCookie('userUUID', userUUID, 365);  // Store the UUID for 1 year
        }

        let ipAddress = await publicIpv4();

        if (!ipAddress) {
            ipAddress = '';
        }

        let uniqueKey = localStorage.getItem('uniqueKey');
        // console.log("uniqueKey123====", uniqueKey);
        if (uniqueKey === null || uniqueKey === '') {
            uniqueKey = crypto.randomUUID();
            localStorage.setItem('uniqueKey', crypto.randomUUID());
        }

        let choice = localStorage.getItem('choice');

        if (choice === null) {
            choice = '1';
        }

        //console.log(crypto.randomUUID())
        setIsLoadingInitial(true)
        streamingData(key, uniqueKey);

        //console.log("uniqueKey sendInitialMessage===", key);

        // Add the user's content to the conversation state
        setConversation((prevConversation) => [
            ...prevConversation,
            { role: 'user' }
        ]);

        setFullConversation((prevFullConversation) => [
            ...prevFullConversation,
            { role: 'user', content: inputQuery, finish_reason: 0 }
        ]);


        // Trigger state to hide suggestions and listen for real-time responses
        setResponseReceived(true);
        setFooterVisible(false);
        setLoadingResponse(true);
        const filterRequest = Axios.CancelToken.source();
        try {
            const response = await Axios.post(generateAskVaSiDotsWebUrl, {
                data: {
                    "query": inputQuery,
                    "fullConversation": fullConversation,
                    "transactionId": key,
                    "uniqueKey": uniqueKey,
                    "userUUID": userUUID,
                    "choice": Number(choice),
                    "ipAddress": ipAddress
                }
            },
                {
                    headers: {
                        'Content-Type': 'application/json', // Set the correct content type for JSON
                        // 'Authorization': `Bearer ${yourToken}`, // Example for adding authorization                       
                    },
                    cancelToken: filterRequest.token
                });
            let botMessage;
            //console.log(response.data.result.status);
            if (response.data.result.status === 200) {
                // After getting the bot's reply, update the conversation state
                setConversation([]);
                if (choice === '2') {
                    botMessage = {
                        role: 'system',
                        content: response.data.result.response.message.content,
                    };

                    if (response.data.result.similarDots.length > 0) {
                        botMessage = {
                            ...botMessage, // Spread the existing properties of botMessage (role and content)
                            similarDots: response.data.result.similarDots, // Add similarDots to the message
                        };
                    }

                } else {
                    botMessage = {
                        role: 'system',
                        content: response.data.result.reply,
                        finish_reason: response.data.result.finish_reason
                    };
                }

                if (Object.keys(response.data.result.reminders).length > 0) {
                    botMessage = {
                        ...botMessage, // Spread the existing properties of botMessage (role and content)
                        reminders: response.data.result.reminders, // Add reminders to the message
                    };
                }

            } else {
                botMessage = {
                    role: 'system',
                    content: `Your free limit usage has been exhausted. To continue conversation download our app from Playstore /Appstore or download using the link below:
                    <div class="app-buttons"><a target="_blank" rel="noreferrer" href="https://play.google.com/store/apps/details?id=com.learnee.vasi"><img src="${GooglePlayImg}" alt="Google Play" /></a><a href="https://apps.apple.com/us/app/vasi-extend-your-mind/id1488476877" target="_blank" rel="noreferrer"><img src="${AppStoreImg}" alt="App Store" /></a></div>`,
                    finish_reason: 0
                };

            }           

            setFullConversation((prevFullConversation) => {
                const updatedConversation = [...prevFullConversation, botMessage];
                setConversation([]);
                // Store the updated conversation in localStorage
                localStorage.setItem('conversation', JSON.stringify(updatedConversation));
                return updatedConversation;
            });



            //console.log("fullConversation==", fullConversation);
            //setIsOngoingResponse(false);
            // After getting the bot's reply, the messages will also be stored in the Realtime DB.
            //console.log('Initial bot reply:', response);
            setLoadingResponse(false);
            setIsLoadingInitial(false)
        } catch (error) {
            setConversation([]);
            setIsLoadingInitial(false)
            setLoadingResponse(false); // Stop loading if there’s an error
            console.error('Error sending content to Firebase Function:', error);
        }

        setConversation([]);
    };

    const handleCategoriesOptionSelect = (option) => {
        if (loadingResponse) {
            return
        }

        contentEditableRef.current.innerText = ''; // Clear the content
        setUserInput('');
        setSelectedOption(option);
        setConversation([]);

        // Assign choice values based on the option selected
        switch (option) {
            case 'personal':
                setSuggestions(suggestions_p)
                localStorage.setItem('choice', '1');
                break;
            case 'explore':
                setSuggestions(suggestions_e)
                localStorage.setItem('choice', '2');
                break;
            case 'publish':
                setSuggestions(suggestions_m)
                localStorage.setItem('choice', '3');
                break;
            default:
                setSuggestions(suggestions_p)
                localStorage.setItem('choice', '1');
        }

        if (selectedOption !== option) {
            setResponseReceived(false);
            setFullConversation([]);
            setIsConvesation(false);
            // Remove 'conversation' from localStorage when selection changes
            if (localStorage.getItem('conversation')) {
                localStorage.removeItem('conversation');
                console.log('Conversation item removed from localStorage');
            }
        }
        setShowOptions(false); // Hide options after selection
        console.log('Selected Option:', option); // Handle the selected option
    };

    // Function to map the numeric choice value to string options
    const getOptionFromChoiceValue = (choice) => {
        switch (choice) {
            case 1:
                return 'personal';
            case 2:
                return 'explore';
            case 3:
                return 'publish';
            default:
                return 'personal'; // Default to 'personal' if invalid value
        }
    };


    // Scroll to the bottom when the arrow is clicked
    const handleScrollToBottom = () => {
        if (bottomRef.current) {
            bottomRef.current.scrollIntoView({ behavior: 'smooth' });

        }
    };

    // Auto-scroll to the bottom when new messages are added
    useEffect(() => {

        const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
        //const scrollHeight = document.documentElement.scrollHeight;
        //const clientHeight = window.innerHeight;

        // Adding a small threshold to detect when you're at the bottom
        //const isAtBottom = scrollTop + clientHeight >= scrollHeight - 10;

        if (scrollTop) {
            setShowArrow(true);
            //bottomRef.current.scrollIntoView({ behavior: 'smooth' });
        } else {
            setShowArrow(false);
        }
    }, [fullConversation]);
    // Trigger when fullConversation changes (new messages)

    // Handle window scroll to show/hide the arrow
    useEffect(() => {
        const handleWindowScroll = () => {
            const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
            const scrollHeight = document.documentElement.scrollHeight;
            const clientHeight = window.innerHeight;

            // Adding a small threshold to detect when you're at the bottom
            const isAtBottom = scrollTop + clientHeight >= scrollHeight - 50;

            if (!isAtBottom) {
                setShowArrow(true); // Show arrow if the user is not at the bottom
            } else {
                setShowArrow(false); // Hide arrow if the user reaches the bottom
            }
        };

        // Attach the scroll event listener to the window
        window.addEventListener('scroll', handleWindowScroll);

        // Cleanup function to remove the scroll event listener
        return () => {
            window.removeEventListener('scroll', handleWindowScroll);
        };
    }, []); // Run once when the component mounts


    // Function to handle content sending
    const handleSendMessage = () => {
        // Clear user input after sending
        setUserInput('');
        contentEditableRef.current.innerText = ''; // Clear the content
        setIsAnimated(true);
        document.body.classList.add('body-background');
        sendInitialMessage(''); // Call the function to send the initial content
    };


    useEffect(() => {
        const savedPage = localStorage.getItem('page');

        const conversationLocalStorage = localStorage.getItem('conversation');
        if (conversationLocalStorage) {
            setIsConversation(true);
        }

        //console.log("conversation===",conversation)
        if (savedPage) {
            handlePageLoad(savedPage);
        } else {
            document.body.classList.add('no-scroll');
            document.querySelector('.page-0').classList.add('active');
            animateThumbnails('page-0');
            if (isLoadedGif) {

                document.querySelector('.text-sequence').classList.add('word');

                const timer = setTimeout(() => {
                    showNextPage();
                }, 4000); // Optional delay for better UX
                return () => clearTimeout(timer); // Cleanup timeout
            }

            // setTimeout(() => {
            //     showNextPage();
            // }, 1000);


        }
    }, [isLoadedGif]);

    const startTypewriter = (text, speed, typewriterIndex, setTypewriterText, setTypewriterIndex) => {
        const typeCharacter = () => {
            setShowCircle(true)
            if (typewriterIndex < text.length) {
                const timeout = setTimeout(() => {
                    setTypewriterText((prevText) => prevText + text[typewriterIndex]);
                    setTypewriterIndex((prevIndex) => prevIndex + 1);

                    // Recursively call typeCharacter until the entire text is typed
                    startTypewriter(text, speed, typewriterIndex + 1, setTypewriterText, setTypewriterIndex);
                }, speed);

                return () => clearTimeout(timeout); // Cleanup timeout if needed
            }
            else {
                // Once the text is fully typed, trigger the blinking animation once
                setBlinking(true);
                setTimeout(() => {
                    setBlinking(false); // Stop the blinking after the animation
                    setShowCircle(false); // Hide the circle
                }, 1000); // Blink for 1 second
            }
        };

        typeCharacter(); // Start typing
    };

    // Blink the cursor every 500ms
    useEffect(() => {
        const cursorInterval = setInterval(() => {
            //setIsCursorVisible((prev) => !prev);
        }, 500);

        return () => clearInterval(cursorInterval);
    }, []);

    const animateThumbnails = (page) => {
        const items = document.querySelectorAll(`.${page} .grid-item`);
        items.forEach((item, index) => {
            const direction = page === 'page-1' ? (index % 2 === 0 ? 'left' : 'right') : (index % 2 === 0 ? 'top' : 'bottom');
            item.classList.add(`${page === 'page-1' ? 'first' : 'second'}-page-shuffle-${direction}`);
        });

        setTimeout(() => {
            animateCaptions(page);
        }, 10);
    };

    const animateCaptions = (page) => {
        const items = document.querySelectorAll(`.${page} .caption`);
        items.forEach((label) => {
            setTimeout(() => {
                label.style.opacity = 1;
            }, 300);
        });
    };

    const handlePageLoad = (page) => {
        document.body.classList.remove('no-scroll');
        document.querySelector('.navbar').classList.remove('hidden');
        document.querySelector('.page-1').classList.remove('active');
        document.querySelector('.page-2').classList.remove('active');
        document.querySelector('.page-3').classList.remove('active');
        if (isBottomOpen) {
            document.querySelector('.page-bottom').classList.remove('active');
            document.getElementById('page-bottom').classList.add('background-blurred');
        } else {

            document.getElementById('page-0').classList.remove('active');
            document.getElementById('page-0').classList.add('display-none');
            document.getElementById('bubbleImage').classList.add('hide');

            document.getElementById('askvasi-landing').classList.remove('flex');

            document.getElementById('page-6').classList.add('active');
            setFooterVisible(true);
        }

        setNextButtonVisible(false);
    };

    const showNextPage = (NextPageSelected) => {

        if (isProcessing) return; // Prevent further clicks
        setIsProcessing(true);

        let nextOverlay = currentPage + 1;
        let NextPage = `page-${nextOverlay}`;

        if (NextPageSelected) {
            NextPage = NextPageSelected;
        }
        //console.log("NextPage==", NextPage);
        switch (NextPage) {
            case 'page-1':
                setNextButtonVisible(true);
                document.querySelector('.page-1').classList.add('active');
                document.getElementById('page-0').classList.add('background-blurred');
                document.getElementById('page-0').classList.remove('active');
                document.getElementById('page-0').classList.add('display-none');
                animateThumbnails(NextPage);
                break;
            case 'page-2':
                document.querySelector('.page-1').classList.remove('active');
                document.querySelector('.page-2').classList.add('active');
                document.getElementById('page-1').classList.add('background-blurred');
                animateThumbnails(NextPage);
                break;
            case 'page-3':
                document.querySelector('.page-2').classList.remove('active');
                document.getElementById('page-2').classList.add('background-blurred');
                document.querySelector('.page-3').classList.add('active');
                animateThumbnails(NextPage);
                break;
            case 'page-bottom':
                document.body.classList.remove('no-scroll');
                document.querySelector('.page-6').classList.remove('active');
                document.querySelector('.navbar').classList.remove('hidden');
                document.getElementById('page-6').classList.add('background-blurred');
                document.getElementById('page-0').classList.add('background-blurred');
                document.getElementById('page-bottom').classList.remove('background-blurred');
                document.querySelector('.page-bottom').classList.add('active');
                document.getElementById('page-bottom').classList.add('visible');
                document.getElementById('bubbleImage').classList.add('hide');
                setNextButtonVisible(false);
                setIsBottomOpen(true);
                break;
            case 'page-4':
                document.body.classList.remove('no-scroll');
                document.querySelector('.page-3').classList.remove('active');
                document.getElementById('page-3').classList.add('background-blurred');
                document.querySelector('.navbar').classList.remove('hidden');
                document.querySelector('.page-bottom').classList.remove('active');
                document.getElementById('page-bottom').classList.add('background-blurred');
                document.getElementById('page-6').classList.add('active');
                setNextButtonVisible(false);
                setFooterVisible(true);
                localStorage.setItem('page', NextPage);
                break;
            case 'page-5':

            //console.log("NextPage==", NextPage);
                document.querySelector('.page-6').classList.remove('active');
                document.getElementById('page-6').classList.add('background-blurred');

                document.querySelector('.page-bottom').classList.remove('active');
                document.getElementById('page-bottom').classList.add('background-blurred');

                document.getElementById('page-0').classList.remove('active');
                document.getElementById('page-0').classList.add('display-none');
                document.getElementById('bubbleImage').classList.add('hide');

                document.getElementById('askvasi-landing').classList.remove('flex');

                document.body.classList.remove('no-scroll');
                document.getElementById('overlayPage').classList.add('active', 'visible');

                const speed = 50; // Typing speed
                setTypewriterText(""); // Reset the text
                setTypewriterIndex(0); // Reset the index

                startTypewriter(text, speed, 0, setTypewriterText, setTypewriterIndex); // Start typing

                const savedConversation = JSON.parse(localStorage.getItem('conversation')) || [];                

                const storedChoice = parseInt(localStorage.getItem('choice'), 10);
                if (storedChoice && !isNaN(storedChoice)) {
                    const defaultOption = getOptionFromChoiceValue(storedChoice);
                    setSelectedOption(defaultOption); // Set the default option               
                }

                if (savedConversation.length > 0) {
                    setResponseReceived(true);
                    setFooterVisible(false);
                    setIsAnimated(true)
                    setIsConvesation(true);
                    setFullConversation(savedConversation);
                    setTimeout(() => {
                        if (bottomRef.current) {
                            window.scrollTo(0, document.body.scrollHeight);
                        }
                    }, 100); // Delay of 100ms to allow content to load
                    document.body.classList.add('body-background');

                } else {
                    document.body.classList.remove('body-background');
                    setFooterVisible(true);
                }
                break;
            default:
                document.body.classList.remove('no-scroll');
                document.querySelector('.page-bottom').classList.remove('active');
                document.getElementById('page-bottom').classList.add('background-blurred');
                document.getElementById('page-6').classList.add('active');

                document.getElementById('page-0').classList.remove('active');
                document.getElementById('page-0').classList.add('display-none');
                document.getElementById('bubbleImage').classList.add('hide');    
                document.getElementById('askvasi-landing').classList.remove('flex');

                setNextButtonVisible(false);
                break;
        }

        setCurrentPage(nextOverlay);

        // Simulate an asynchronous operation (e.g., API call)
        setTimeout(() => {
            setIsProcessing(false); // Re-enable after action completes
        }, 1000); // Adjust timeout duration based on the action's length
        
    };

    // Handle suggestion click event
    const handleClick = (event, content) => {
        event.preventDefault(); // Prevent the default anchor tag behavior     
        //isLoading
        setLoadingResponse(true)
        if (loadingResponse) {
            console.log('Request already in progress. Please wait.');
            return;
        }
       

        setFooterVisible(false);
        setIsAnimated(true);
        document.body.classList.add('body-background');
        sendInitialMessage(content);
        setIsActive(false);
        setUserInput('');
    };

    // Function to extract relevant content from full HTML
    const extractRelevantContent = (html) => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');

        // Adjust to extract the desired parts from the document
        // For example, extracting everything in the <body>
        return doc.body.innerHTML; // Extracts the inner HTML
    };

    // Function to clean up the input, removing markdown-like backticks
    const cleanUpInput = (input) => {
        return input.replace(/```html/g, '').replace(/```/g, '').trim(); // Remove code block markers
    };

    const sanitizedContent = (content) => {
        if (content) {
            // First, convert Markdown to HTML
            const cleanedContent = cleanUpInput(content); // Clean up the input
            const relevantContent = extractRelevantContent(cleanedContent); // Extract relevant HTML

            const sanitizedContent = DOMPurify.sanitize(relevantContent, {
                FORBID_ATTR: ['style'], // Remove style attributes
                ALLOWED_TAGS: ['b', 'i', 'em', 'strong', 'a', 'p', 'ul', 'ol', 'li',
                    'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'blockquote', 'code', 'pre',
                    'div', 'span', 'img', 'table', 'tr', 'td', 'th', '<br>', '</br>'], // Allow specific tags
                ALLOWED_ATTR: ['href', 'target', 'rel', 'src', 'alt', 'class'],
            });

            //const htmlContent = escapeHtml(sanitizedContent)

            return sanitizedContent;

        } else {
            return '';
        }


    }

    // Function to handle key presses globally
    const handleKeyPressWindow = (event) => {
        const { key, code } = event;
        const isTargetKey = key === 'ArrowDown' || key === 'Enter' || code === 'Space';

        if (isTargetKey) {
            //console.log(`${key} key pressed`);
            const activeElement = document.querySelector('.slide-up-overlay-features.active');
            //event.preventDefault();
            if (activeElement) {

                showNextPageOnKeyDown(activeElement);
            }
        } else {
            console.log("none");
        }
    };


    const showNextPageOnKeyDown = (activeElement) => {
      
        // Get the current ID
        let currentId = activeElement.id;
        // Extract the numeric part from the ID (assuming the format 'page-x')
        let numberPart = currentId.split('-')[1]; // Get the number after 'page-'
        let incrementedNumber = parseInt(numberPart) + 1; // Increment the number
        // Create the new ID
        let newId = 'page-' + incrementedNumber;
        // Call your function for Enter key
        showNextPage(newId);
    }

    useEffect(() => {
        // Add keydown event listener on window
        window.addEventListener('keydown', handleKeyPressWindow);

        // Cleanup event listener when the component unmounts
        return () => {
            window.removeEventListener('keydown', handleKeyPressWindow);
        };
    }, []);



    // Handle input inside contenteditable div
    const handleInput = (e) => {
        setUserInput(e.target.innerText); // Update state with the plain text (innerText)
    };

    // Handle key presses for Enter, Ctrl+Enter, Alt+Enter
    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            // If only Enter is pressed, submit the form
            if (!e.ctrlKey && !e.altKey) {
                e.preventDefault(); // Prevent adding a new line
                handleSendMessage(); // Call the function to submit the form
                contentEditableRef.current.innerText = ''; // Clear the content
                setUserInput(''); // Clear the state
            }
            // If Ctrl+Enter or Alt+Enter is pressed, add a new line
            else if (e.ctrlKey || e.altKey) {
                e.preventDefault(); // Prevent the default action of form submission
                document.execCommand('insertLineBreak'); // Insert a new line (inserting <br> tag)
            }
        }
    };

    return (
        <div className="askvasi-home landing-page flex" id='askvasi-landing' style={{
            height: '100vh',
            // display: 'flex'
        }}>
            {/* Navbar */}
            <NavBar />

            <div className="grid-container page-0" id="page-0">
                <div className="container gif-container">
                    <div>
                        <img loading="lazy" style={{ visibility: isLoadedGif ? 'visible' : 'hidden' }} onLoad={handleImageLoad} src={gifSrc} alt="vasi animated" className="animated-icon" />

                    </div>
                    <div
                        className="text-sequence"
                    > Extend your Mind
                        {/* <span className="word">Extend</span>
                        <span className="word">your</span>
                        <span className="word">Mind</span> */}
                    </div>
                </div>

            </div>


            {/* Page 1 */}
            <div className="grid-container slide-up-overlay-features page-1" id="page-1">
                <div id="centerText">
                    <span className="topText">Monetize</span>
                    <span className="bottomText">with VaSi</span>
                </div>

                <div className="grid">
                    <div className="grid-item" id="item1">
                        <img src={thumbnail_page_1_img_1} loading="lazy" alt="Lifehacks" />
                        <div className="caption label-animation-page-1-top-1">Lifehacks you know</div>
                    </div>
                    <div className="grid-item" id="item2">
                        <img src={thumbnail_page_1_img_2} loading="lazy" alt="Grandma recipes" />
                        <div className="caption label-animation-page-1-top-2">Your travel hacks ✈️</div>
                    </div>
                    <div className="grid-item" id="item3">
                        <img src={thumbnail_page_1_img_3} loading="lazy" alt="Face pack" />
                        <div className="caption label-animation-page-1-top-3">Share your grandma's recipes 🫕</div>
                    </div>
                    <div className="grid-item" id="item4">
                        <img src={thumbnail_page_1_img_4} loading="lazy" alt="Relationship advice" />
                        <div className="caption label-animation-page-1-bottom-1">Your home-made face pack</div>
                    </div>
                    <div className="grid-item" id="item5">
                        <img src={thumbnail_page_1_img_5} loading="lazy" alt="Travel hacks" />
                        <div className="caption label-animation-page-1-bottom-2">Your best relationship advice 🤍 </div>
                    </div>
                    <div className="grid-item" id="item6">
                        <img src={thumbnail_page_1_img_6} loading="lazy" alt="Book recommendations" />
                        <div className="caption label-animation-page-1-bottom-3">Your top 10 book recommendations 🤓</div>
                    </div>
                </div>
            </div>


            {/* Page 2 */}
            <div className="grid-container page-2 slide-up-overlay-features" id="page-2">
                <div id="centerText">
                    <span className="topText">Personalize</span>
                    <span className="bottomText">with VaSi</span>
                </div>
                <div className="grid">
                    <div className="grid-item" id="item1">
                        <img src={thumbnail_page_2_img_1} loading="lazy" alt="New Content" />
                        <div className="caption label-animation-page-2-top-1">To-do list with reminders ⏰</div>
                    </div>
                    <div className="grid-item" id="item2">
                        <img src={thumbnail_page_2_img_2} loading="lazy" alt="New Content" />
                        <div className="caption label-animation-page-2-top-2">Suggest recipes with left overs 🥗</div>
                    </div>
                    <div className="grid-item" id="item3">
                        <img src={thumbnail_page_2_img_3} loading="lazy" alt="New Content" />
                        <div className="caption label-animation-page-2-top-3">Prompts for emotional journaling ✍🏼</div>
                    </div>
                    <div className="grid-item" id="item4">
                        <img src={thumbnail_page_2_img_4} loading="lazy" alt="New Content" />
                        <div className="caption label-animation-page-2-bottom-1">Plan my budget trip 🚌</div>
                    </div>
                    <div className="grid-item" id="item5">
                        <img src={thumbnail_page_2_img_5} loading="lazy" alt="New Content" />
                        <div className="caption label-animation-page-2-bottom-2">Help me meditate with a schedule 🧘🏼‍♀️</div>
                    </div>
                </div>

            </div>


            {/* Page 3 */}
            <div className="grid-container page-3 slide-up-overlay-features" id="page-3">
                <div id="centerText">
                    <span className="topText">Explore</span>
                    <span className="bottomText">with VaSi</span>
                </div>
                <div className="grid">
                    <div className="grid-item" id="item1">
                        <img src={thumbnail_page_3_img_1} loading="lazy" alt="Latest Content" />
                        <div className="caption label-animation-page-3-top-1">How to make right decisions?</div>
                    </div>
                    <div className="grid-item" id="item2">
                        <img src={thumbnail_page_3_img_2} loading="lazy" alt="Latest Content" />
                        <div className="caption label-animation-page-3-top-2">Money Management Toolkit</div>
                    </div>
                    <div className="grid-item" id="item3">
                        <img src={thumbnail_page_3_img_3} loading="lazy" alt="Latest Content" />
                        <div className="caption label-animation-page-3-top-3">Unique Aloo Gobhi recipe</div>
                    </div>
                    <div className="grid-item" id="item4">
                        <img src={thumbnail_page_3_img_4} loading="lazy" alt="Latest Content" />
                        <div className="caption label-animation-page-3-bottom-1">Habits of Failure📚</div>
                    </div>
                    <div className="grid-item" id="item5">
                        <img src={thumbnail_page_3_img_5} loading="lazy" alt="Latest Content" />
                        <div className="caption label-animation-page-3-bottom-2">Trick your brain into reading📚</div>
                    </div>

                </div>

            </div>


            {/* Page 4 and beyond */}
            <div className="page-bottom slide-up-overlay-slide-4" id="page-bottom">
                <div className="grid">
                    {/* New Section (Initially hidden) */}
                    <div className="new-section">
                        <span className='page-headings'>Try it out! Experience the magic</span>

                        <div className="card-container-mobile">
                            {/* First Card */}
                            <div onClick={() => { showNextPage('page-5'); localStorage.setItem('choice', '1'); setSelectedOption('personal'); setSuggestions(suggestions_p); }} className="card card-1">
                                <div id="box-1" style={{ margin: 'auto', position: 'relative' }}>
                                    <img src={box1_img_3} loading="lazy" alt="Personal Use1" width="40px" />
                                    <img src={box1_img_2} loading="lazy" alt="Personal Use2" width="40px" />
                                    <img src={box1_img_1} loading="lazy" alt="Personal Use3" width="80px" />
                                </div>
                                <h3 style={{ fontWeight: 600 }}>Personal Use</h3>
                                <p>Your little virtual assistant</p>
                            </div>

                            {/* Second Card */}
                            <div onClick={() => { showNextPage('page-5'); localStorage.setItem('choice', '2'); setSelectedOption('explore'); setSuggestions(suggestions_e); }} className="card card-2">
                                <div id="box-2" style={{ margin: 'auto', position: 'relative' }}>
                                    <img src={box2_img_3} loading="lazy" alt="Explore1" width="40px" />
                                    <img src={box2_img_2} loading="lazy" alt="Explore2" width="70px" />
                                    <img src={box2_img_1} loading="lazy" alt="Explore3" width="50px" />
                                </div>
                                <h3 style={{ fontWeight: 600 }}>Explore</h3>
                                <p>Learn from others’ experiences</p>
                            </div>

                            {/* Third Card */}
                            <div onClick={() => { showNextPage('page-5'); localStorage.setItem('choice', '3'); setSelectedOption('publish'); setSuggestions(suggestions_m); }} className="card card-3">
                                <div id="box-3" style={{ margin: 'auto', position: 'relative' }}>
                                    <img src={box3_img_3} loading="lazy" alt="Publish1" width="60px" />
                                    <img src={box3_img_2} loading="lazy" alt="Publish2" width="70px" />
                                    <img src={box3_img_1} loading="lazy" alt="Publish3" width="50px" />
                                </div>
                                <h3 style={{ fontWeight: 600 }}>Publish</h3>
                                <p>Monetize your knowledge</p>
                            </div>
                        </div>

                        <div className="card-container circle-container">
                            {/* First Card */}
                            <div onClick={() => { showNextPage('page-5'); localStorage.setItem('choice', '1'); setSelectedOption('personal'); setSuggestions(suggestions_p); }} className="card card-1 card1">
                                <div id="box-1" style={{ margin: 'auto', position: 'relative' }}>
                                    <img src={box1_img_3} loading="lazy" alt="Personal Use1" width="40px" />
                                    <img src={box1_img_2} loading="lazy" alt="Personal Use2" width="40px" />
                                    <img src={box1_img_1} loading="lazy" alt="Personal Use3" width="80px" />
                                </div>
                                <h3 style={{ fontWeight: 600 }}>Personal Use</h3>
                                <p>Your little virtual assistant</p>
                            </div>

                            {/* Second Card */}
                            <div onClick={() => { showNextPage('page-5'); localStorage.setItem('choice', '2'); setSelectedOption('explore'); setSuggestions(suggestions_e); }} className="card card-2 card2">
                                <div id="box-2" style={{ margin: 'auto', position: 'relative' }}>
                                    <img src={box2_img_3} loading="lazy" alt="Explore Use1" width="40px" />
                                    <img src={box2_img_2} loading="lazy" alt="Explore Use2" width="70px" />
                                    <img src={box2_img_1} loading="lazy" alt="Explore Use3" width="50px" />
                                </div>
                                <h3 style={{ fontWeight: 600 }}>Explore</h3>
                                <p>Learn from others’ experiences</p>
                            </div>

                            {/* Third Card */}
                            <div onClick={() => { showNextPage('page-5'); localStorage.setItem('choice', '3'); setSelectedOption('publish'); setSuggestions(suggestions_m); }} className="card card-3 card3">
                                <div id="box-3" style={{ margin: 'auto', position: 'relative' }}>
                                    <img src={box3_img_3} loading="lazy" alt="Monetize Use1" width="60px" />
                                    <img src={box3_img_2} loading="lazy" alt="Monetize Use2" width="70px" />
                                    <img src={box3_img_1} loading="lazy" alt="Monetize Use3" width="50px" />
                                </div>
                                <h3 style={{ fontWeight: 600 }}>Publish</h3>
                                <p>Monetize your knowledge</p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Center circle */}
                <div className="center-circle">
                    <h3>AskVaSi</h3>
                    <p>Turn Experience Into Earnings,<br /> Guidance Into Gains</p>
                </div>
            </div>

            {/* Global Next Arrow Button */}
            {isNextButtonVisible &&
                // <button className="next-arrow-global" id="nextButton" onClick={() => showNextPage()}>↓</button>

                <div className="next-arrow-global" id="nextButton" onClick={() => showNextPage()}>
                    Next {/*  &#x2193; Down arrow symbol */}
                </div>

            }
            {/* Page 5 and beyond */}
            <div className={`slide-up-overlay-slide-5 page-5 ${isAnimated ? 'animated' : ''}  ${isActive ? 'active' : ''}`} id="overlayPage">
                <div className={`container ${isConvesation ? 'conversation' : ''}`} id='chat_box_container'>

                    {/* Conditionally render the suggestions section selectedOption */}
                    {!responseReceived && (
                        <>
                            <div className="suggestions-section-top">
                                <span className="suggestion-heading">{typewriterText}  {showCircle && (<span
                                    className="suggestion-heading-title" style={{
                                        animation: isBlinking ? 'blink 3s 3' : 'none',
                                    }}
                                />)}</span>

                            </div>


                        </>
                    )}


                    {/* Display the conversation */}
                    {responseReceived &&
                        <div className="conversation-box scroll-container">

                            {(() => {


                                return fullConversation.map((entry, index) => (
                                    <div
                                        key={index}
                                        className={entry.role === 'system' ? 'bot-response-container' : ''}
                                    >
                                        {/* Display the bot icon if it's a system response */}
                                        {entry.role === 'system' && (
                                            <img src={vasi_logo_circle} loading="lazy" alt="vasi" className="bot-icon" />
                                        )}

                                        <div
                                            className={`chat-bubble ${entry.role === 'user' ? 'user-bubble' : 'bot-bubble'}`}
                                        >
                                            <div
                                                className="chat-message"
                                                dangerouslySetInnerHTML={{ __html: sanitizedContent(entry.content) }}
                                            />

                                            {/* Conditionally render the Reminders component */}
                                            {entry.reminders && (
                                                <Reminders remindersData={entry.reminders} />
                                            )}

                                            {/* Conditionally render the CTA for system responses only */}
                                            {entry.role === 'system' && entry.finish_reason === 1 &&
                                                selectedOption !== 'explore' && (
                                                    <div className="cta-container">
                                                        <a target="_blank" rel="noreferrer" href="https://learnee.page.link/rniX" className="cta-button-link">
                                                            <img width={24} src={saveIcon} alt="save" style={{
                                                                marginRight: '5px',
                                                            }} />
                                                            {selectedOption === 'personal' && 'Save this response as Dot'}
                                                            {selectedOption === 'publish' && 'Get started with this outline'}
                                                        </a>

                                                    </div>
                                                )}

                                            {/* Conditionally render the SimilarDots component */}
                                            {entry.similarDots && entry.similarDots.length > 0 && (
                                                <SimilarDots similarDots={entry.similarDots} />
                                            )}

                                        </div>
                                    </div>
                                ));
                            })()}

                            {conversation && conversation.map((entry, index) => (

                                <div key={index}
                                    // Conditionally add 'bot-response-container' for even index, else add an empty class
                                    className={entry.role === 'system' ? 'bot-response-container' : ''}
                                >
                                    {entry.role === 'system' && (
                                        <img src={vasi_logo_circle} loading="lazy" alt="vasi" className="bot-icon" />
                                    )}

                                    <div
                                        key={index}
                                        className={`testing ${entry.role === 'user' ? '' : 'chat-bubble bot-bubble'}`}
                                    >
                                        <div
                                            className="chat-message"
                                            dangerouslySetInnerHTML={{ __html: sanitizedContent(entry.content) }}
                                        />
                                    </div>
                                </div>
                            ))}

                            {/* Show a loading indicator while waiting for the bot response */}
                            {isLoadingInitial && <span className="blinking-circle"></span>}

                        </div>

                    }
                    <div ref={bottomRef}></div>
                </div>

                {/* Scroll to bottom arrow */}
                {showArrow && (
                    <img onClick={handleScrollToBottom} className="scroll-arrow" src={DownButton} alt="Down Button" width="40px" />
                )}


                {!responseReceived && (
                    <>
                        <div className="suggestions-container">
                            <div
                                className={`suggestions-wrapper ${isTransitioning ? 'fade-out' : 'fade-in'}`}
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                            >
                                {/* {suggestions.map((content, index) => ( */}
                                {displayedSuggestions.map((content, index) => (
                                    <button onClick={(event) => handleClick(event, content)} key={index} className={`suggestion-button rounded-full border border-token-border-light ${selectedOption}`} > {content}</button>
                                ))}
                            </div>
                        </div>
                        <div className='suggestions-container-mobile'>
                            <SuggestionCarousel handleClick={handleClick} selectedOption={selectedOption} suggestions={suggestions} />
                        </div>


                    </>
                )}

                <div className="chat-bar" id='chat_bar'>
                    {/* Icon for displaying options */}
                    <button className="chat-switch-icon" onClick={() => setShowOptions(!showOptions)}>
                        <img src={icons[selectedOption]} alt="Selected Option" />
                    </button>

                    {/* Options Menu */}
                    {showOptions && (
                        <div ref={menuRef} className="chat-options-menu">
                            <div className="chat-option" onClick={() => handleCategoriesOptionSelect('personal')}>
                                <img src={icons.personal} alt="Personal" width="32px" />
                                <span className='categoryType'>Personal</span>
                                {selectedOption === 'personal' && (
                                    <span className="checkmark">✔</span>
                                )}
                            </div>
                            <div className="chat-option" onClick={() => handleCategoriesOptionSelect('explore')}>
                                <img src={icons.explore} alt="Explore" width="32px" />
                                <span className='categoryType'>Explore</span>
                                {selectedOption === 'explore' && (
                                    <span className="checkmark">✔</span>
                                )}
                            </div>
                            <div className="chat-option" onClick={() => handleCategoriesOptionSelect('publish')}>
                                <img src={icons.publish} alt="Publish" width="32px" />
                                <span className='categoryType'>Publish</span>
                                {selectedOption === 'publish' && (
                                    <span className="checkmark">✔</span>
                                )}
                            </div>
                        </div>
                    )}

                    <div
                        ref={contentEditableRef}
                        contentEditable={!loadingResponse}
                        onInput={handleInput}
                        onKeyDown={handleKeyDown}
                        className="chat-input"
                        data-placeholder="Instruct AskVaSi..." // Use a placeholder-like effect via CSS
                        disabled={loadingResponse} // Make sure to disable it when needed
                    />


                    <button disabled={loadingResponse} className={`chat-send-btn ${(loadingResponse || userInput === '') ? 'disabled' : ''}`} onClick={handleSendMessage}>
                        {/* <img src={send_btn} alt="Send" width="28px" /> */}
                    </button>



                </div>

                {/* <div className='footer-bottom-chat'>
                    <p>By using VaSi, you agree to our <a href='https://www.vasi.ai/terms-of-service-usa'>Terms</a> and <a href='https://www.vasi.ai/privacy-policy-usa'>Provacy Policy</a></p>
                </div> */}

                {/* {isFooterVisible &&
                    <LandingPageFooter />
                } */}

            </div>

            {/* Page 6 and beyond */}
            <div className="page-wrapper slide-up-overlay-slide-6 page-6" id="page-6">
                {/* Container for the two columns */}
                <div className="hero-section-container">
                    {/* Left Column: Overlay content */}
                    <div className="overlay-content-hero-section">

                        <div className='hero-title'><span className='hero-title-1'>Your next <TextSlider texts={words} interval={interval} duration={duration} /></span>
                            {/* <span className={`fade-in-title fade ${fadeIn ? 'in' : 'out'}`}>{words[currentWordIndex]}</span> */}
                            <br></br>is on us, Just share
                            <br></br>your Wisdom</div>
                        <p>Ai to turn your wisdom, experiences, and expertise into income</p>
                        <button onClick={() => showNextPage(isConversation ? 'page-5' : 'page-bottom')} className="cta-button">{isConversation === true ? 'Resume VaSi' : 'Unlock VaSi'}</button>
                    </div>

                    <DotsImagesMobileCarousel />


                    {/* Right Column: Blurred images container */}
                    <div className="blurred-images-container-hero-section">
                        <div className="blurred-image image-1">
                            <a target='_blank' rel='noopener noreferrer' href="https://learnee.page.link/gnZddAtLfTu7ARBj8"><img src={thumbnail_page_5_img_3} loading="lazy" alt="Blurred Dots 3" /></a>
                        </div>
                        <div className="blurred-image image-2">
                            <a target='_blank' rel='noopener noreferrer' href="https://learnee.page.link/GJe2WwtFNcHkCJM16"><img src={thumbnail_page_5_img_2} loading="lazy" alt="Blurred Dots 2" /></a>
                        </div>
                        <div className="blurred-image image-3">
                            <a target='_blank' rel='noopener noreferrer' href="https://learnee.page.link/EMda9rS37HdzxGaz8"><img src={thumbnail_page_5_img_1} loading="lazy" alt="Blurred Dots 1" /></a>
                        </div>

                        <div className="blurred-image image-4">
                            <a target='_blank' rel='noopener noreferrer' href="https://learnee.page.link/KiX3nHr7xamYw3qD6"><img src={thumbnail_page_5_img_4} loading="lazy" alt="Blurred Dots 4" /></a>
                        </div>
                        <div className="blurred-image image-5">
                            <a target='_blank' rel='noopener noreferrer' href="https://learnee.page.link/iyXHC1Yapi9A33vq7"><img src={thumbnail_page_5_img_5} loading="lazy" alt="Blurred Dots 5" /></a>
                        </div>
                        <div className="blurred-image image-6">
                            <a target='_blank' rel='noopener noreferrer' href="https://learnee.page.link/o8sKaBqYFHyT3zk8A"><img src={thumbnail_page_5_img_6} loading="lazy" alt="Blurred Dots 6" /></a>
                        </div>
                    </div>

                </div>


                {isFooterVisible &&
                    <LandingPageFooter />
                }
            </div>



            <div className="background-container" id="bubbleImage"></div>
        </div>
    );
}
export default App;