import React from 'react';
//import { useRecoilState } from "recoil";
import { Link } from "react-router-dom"
//import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
//import loader from "../assets/images/loader.gif"
import Slider from "react-slick";
import { PrevArrow, NextArrow } from './CustomArrows'; // Adjust the path as needed


import defaultAvatar from "../assets/images/mystery-man.jpg"
//import { freeDots, formatWithMonthName } from "./utils"

const PublicViewListing = (props) => {

    //console.log(props.dotLists)
    const dotListing = props.dotLists;

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        //centerMode: true,       
        autoplay: true,
        autoplaySpeed: 5000,
        lazyLoad: true,
        pauseOnHover: true,
        prevArrow: <PrevArrow />,
        nextArrow: <NextArrow />,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    centerPadding: '40px',
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    centerPadding: '20px'
                },
            },
        ]
    };


    return (
        <div className="slider-container position-relative">
            <Slider {...settings}>
                {dotListing.length > 0 &&
                    dotListing.map((post, index) => {
                        return (
                            //onClick={saveElement.bind()}
                            <Link to={`/dots?mktDotId=${post.mktPlaceId}`} key={index}>
                                <div className="card card-margin-auto roundedCornerH more-dots card-hover-shadow" key={index} id={`element-${index}`}>
                                    <div className='clearfix card-header-public'>
                                        <div className='title-width'>
                                            <strong className="blog-title box-title">{post.dotTitle.substring(0, 50)}</strong>
                                        </div>
                                        <div className="d-flex flex-wrap align-items-center box-lower-head">
                                            <span className="me-2">
                                                Views: {post.views}
                                            </span>
                                            <span className="me-2">|</span>
                                            <span className="d-flex align-items-center">
                                                <img className="avatar-tiny rounded-circle dots-avatar-bg me-2" alt="profile" src={(post.userAvatar === '' || post.userAvatar === null ? defaultAvatar : post.userAvatar)} />
                                                <span className="mb-0 font-weight-normal">{post.creator}</span>
                                            </span>
                                        </div>
                                    </div>


                                    {post &&
                                        <div className="row margin-auto margin-top-details-top">
                                            <div className="col-sm-12 blog-main">
                                                <div className="blog-post">
                                                    <div className="para-perm desc box-details">{post.dotDesc.substring(0, 230)}...</div>
                                                </div>
                                            </div>

                                            <div className="clearfix p1 read-more-bottom">Read More</div>
                                        </div>

                                    }

                                </div>
                            </Link>
                        )
                    })}
            </Slider>
        </div>

    );


};

export default PublicViewListing;