import React from "react";
import { Link } from "react-router-dom"
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

//import { PrevArrow, NextArrow } from './CustomArrows'; // Adjust the path as needed

// Import the images
import thumbnail_page_5_img_1 from '../assets/images/landing-page/1st.png';
import thumbnail_page_5_img_2 from '../assets/images/landing-page/2nd.png';
import thumbnail_page_5_img_3 from '../assets/images/landing-page/3rd.png';
import thumbnail_page_5_img_4 from '../assets/images/landing-page/4th.png';
import thumbnail_page_5_img_5 from '../assets/images/landing-page/5th.png';
import thumbnail_page_5_img_6 from '../assets/images/landing-page/6th.png';

// The main component
// const BlurredImagesMobileCarousel = () => {
//     const [activeImages, setActiveImages] = useState([0, 1]); // Two active images

//     const images = [
//         { src: thumbnail_page_5_img_1, link: "https://learnee.page.link/EMda9rS37HdzxGaz8" },
//         { src: thumbnail_page_5_img_2, link: "https://learnee.page.link/GJe2WwtFNcHkCJM16" },
//         { src: thumbnail_page_5_img_3, link: "https://learnee.page.link/gnZddAtLfTu7ARBj8" },
//         { src: thumbnail_page_5_img_4, link: "https://learnee.page.link/KiX3nHr7xamYw3qD6" },
//         { src: thumbnail_page_5_img_5, link: "https://learnee.page.link/iyXHC1Yapi9A33vq7" },
//         { src: thumbnail_page_5_img_6, link: "https://learnee.page.link/o8sKaBqYFHyT3zk8A" }
//     ];


//     useEffect(() => {
//         const interval = setInterval(() => {
//             setActiveImages((prev) => {
//                 const newIndex1 = (prev[0] + 1) % images.length;
//                 const newIndex2 = (prev[1] + 1) % images.length;
//                 return [newIndex1, newIndex2];
//             });
//         }, 3000); // Rotate every 3 seconds

//         return () => clearInterval(interval); // Cleanup interval on unmount
//     }, [images.length]);

//     return (
//         <div className="mobile-only-carousel-mobile">
//             <div className="blurred-images-carousel-mobile">
//                 {images.map((image, index) => (
//                     <div
//                         key={index}
//                         className={`carousel-image-mobile ${
//                             activeImages.includes(index) ? 'active-mobile' : ''
//                         } ${index === (activeImages[0] - 1 + images.length) % images.length || index === (activeImages[1] + 1) % images.length ? 'adjacent-mobile' : ''}`}
//                     >
//                         <a href={image.link} target="_blank" rel="noopener noreferrer">
//                             <img src={image.src} alt={`Blurred Dots ${index + 1}`} />
//                         </a>
//                     </div>
//                 ))}
//             </div>
//         </div>
//     );
// };

const DotsImagesMobileCarousel = () => {
    //const DotsImagesMobileCarousel = () => {
    const images = [
        { src: thumbnail_page_5_img_1, link: "https://learnee.page.link/EMda9rS37HdzxGaz8", title: "Things To Know Before You Become An Adult", avatar: "https://firebasestorage.googleapis.com/v0/b/mindi-8edcb.appspot.com/o/UserProfilePictures%2F23adff69ec96f4a331096a34bd406dc9299bb5a655149f424db2430c2d3745cf?alt=media&token=7866e969-3445-48c7-981f-e28d00ede968", creator: "Prakriti" },
        { src: thumbnail_page_5_img_2, link: "https://learnee.page.link/GJe2WwtFNcHkCJM16", title: "Reset Your Life", avatar: "https://firebasestorage.googleapis.com/v0/b/mindi-8edcb.appspot.com/o/UserProfilePictures%2Fe6fe12a56d5d2fd619872aca5293817f3aa79e88e066f09b62b64f86a7dea9a5?alt=media&token=c3794534-e2d4-413a-b52b-5135bf54ab80", creator: "Thinking Factory" },
        { src: thumbnail_page_5_img_3, link: "https://learnee.page.link/gnZddAtLfTu7ARBj8", title: "Safety Hacks for Women", avatar: "https://firebasestorage.googleapis.com/v0/b/mindi-8edcb.appspot.com/o/UserProfilePictures%2Fe6fe12a56d5d2fd619872aca5293817f3aa79e88e066f09b62b64f86a7dea9a5?alt=media&token=c3794534-e2d4-413a-b52b-5135bf54ab80", creator: "Thinking Factory" },
        { src: thumbnail_page_5_img_4, link: "https://learnee.page.link/KiX3nHr7xamYw3qD6", title: "Rewire life in 2 steps", avatar: "https://firebasestorage.googleapis.com/v0/b/mindi-8edcb.appspot.com/o/UserProfilePictures%2F23adff69ec96f4a331096a34bd406dc9299bb5a655149f424db2430c2d3745cf?alt=media&token=7866e969-3445-48c7-981f-e28d00ede968", creator: "Prakriti" },
        { src: thumbnail_page_5_img_5, link: "https://learnee.page.link/iyXHC1Yapi9A33vq7", title: "3 Books To Stop Overthinking", avatar: "https://firebasestorage.googleapis.com/v0/b/mindi-8edcb.appspot.com/o/UserProfilePictures%2Fe6fec32d11903b398a84bc953bc457648812b5f0671bad87c442849c9d64bd02?alt=media&token=3672996e-dad4-428d-b219-d4a63568cd9c", creator: "BookVerse" },
        { src: thumbnail_page_5_img_6, link: "https://learnee.page.link/o8sKaBqYFHyT3zk8A", title: "Speed Reading Techniques", avatar: "https://firebasestorage.googleapis.com/v0/b/mindi-8edcb.appspot.com/o/UserProfilePictures%2Fe6fec32d11903b398a84bc953bc457648812b5f0671bad87c442849c9d64bd02?alt=media&token=3672996e-dad4-428d-b219-d4a63568cd9c", creator: "BookVerse" }
    ];

    const settings = {
        dots: false,
        infinite: true, // Ensure this is set to false
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 1,
        lazyLoad: true,
        // prevArrow: <PrevArrow />,
        // nextArrow: <NextArrow />,
        arrows: false,
        autoplay: true,
        gap: 10,
        centerMode: false, // Disable if enabled
        //adaptiveHeight: true, // Optional: Adjust height based on current slide   
        responsive: [
            {
                breakpoint: 768, // mobile breakpoint
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    centerMode: false, // Disable if enabled
                }
            }
        ]     
    };

    return (
        <div className="similar-dots-container slider-container slide-container-mobile mobile-only-carousel-mobile">
            <div className="discover-header">Explore Dots</div>
            <Slider {...settings}>
                {images.map((image, index) => (
                    <Link target='_blank' to={image.link} key={index}>
                        <img className="featured-dot-image" src={image.src} alt={`Blurred Dots ${index + 1}`} />
                        <div className="featured-dots-overlay">
                            <div className="similar-dot-title">{image.title}</div>
                            <div className="similar-dot-creator">
                                <img className="similar-dot-user-avatar" src={image.avatar} alt="Dot Thumbnail" /> &nbsp;{image.creator}
                            </div>
                        </div>
                    </Link>
                ))}
            </Slider>
        </div>
    );
};

export default DotsImagesMobileCarousel;