import React from "react"
import loader_img from "../assets/images/loader_img.gif"

function LoadingDotsIcon() {
  return (
    <div className="text-center loading-img">
      <img className="loader-image" alt="" src={loader_img} />
      {/*<div className="spinner-grow text-warning" role="status">
        <span className="sr-only"></span>
      </div>
      <div className="spinner-grow text-success" role="status">
        <span className="sr-only"></span>
      </div>
      <div className="spinner-grow text-primary" role="status">
        <span className="sr-only"></span>
      </div>*/}

    </div>
  )
}

export default LoadingDotsIcon
